import React, { useEffect, useState } from "react";
// import OrdersRating from "./OrdersRating";
import OrderDetailsUserCard from "./OrderDetailsUserCrad";
const OrderDetailsUser = ({ order, id }) => {
  const [totalPrice, setTotalPrice] = useState(0);
  useEffect(() => {
    if (order?.order) {
      const calculatedTotalPrice = order.order.reduce((total, item) => {
        return total + item.unit_order.per_unit_price;
      }, 0);
      setTotalPrice(calculatedTotalPrice);
    }
  }, [order]);

  // console.log("~~ order " , order);
  return (
    <>
      <div className="d-flex flex-column align-items-center mt-2">
        <div className="col-12">
          {order?.order.map((orderItem, index) => (
            <OrderDetailsUserCard orderItem={orderItem} id={id} key={index} />
          ))}
        </div>
        {/* <div className="col-12 d-flex justify-content-end">
          <h5 className="fw-bold">Total Price: ${order?.total_price}</h5>
        </div> */}
        {!order.payment_completed && (
          <button
          type="button"
          onClick={() => window.open(order.payment_link, "_blank")}
          className="d-flex align-items-center px-5 p-2"
          style={{
            backgroundColor: '#ffcc00',
            border: 'none',
            borderRadius: '10px',
            color: '#000034',
          }}
        >
          Payment Due
        </button>
          // <button
          //   className="btn text-dark mb-3 mt-3"
          //   onClick={() => window.open(order.payment_link, "_blank")}
          // >
          //   Payment Due
          // </button>
        )}
      </div>
    </>
  );
};

export default OrderDetailsUser;
