import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { GiOrange } from "react-icons/gi";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Modal, Button, Form } from "react-bootstrap";
import Spinner from "../../../shared-components/Spinner/Spinner";
import { loginUser } from "../../../actions/userActions";
import { USER_REGISTER_RESET } from "../../../constants/userConstants";
import { getAllServiceProviders } from "../../../actions/serviceProviderActions";
import { RiLock2Line } from "react-icons/ri";
import { MdOutlineMailOutline } from "react-icons/md";
import { GrPrevious } from "react-icons/gr";
import { PiSignIn } from "react-icons/pi";
import { MdCheckBox } from "react-icons/md";
import { FcGoogle } from "react-icons/fc";
import { IoEyeSharp } from "react-icons/io5";
import { IoEyeOff } from "react-icons/io5";

const SignIn = () => {
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  const { loading, user, error } = useSelector((state) => state.userLogin);
  const { info, loading: loadingInfo } = useSelector((state) => state.userInfo);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch({ type: USER_REGISTER_RESET });
    if (user) {
      if (user.service_provider && user.is_provider_verified) {
        // if (user.is_stripe_account_active && user.is_docusign_completed) {
        //   dispatch(getAllServiceProviders());
        //   navigate("/service-provider-dashboard/analytics");
        // } else if (!user.is_stripe_account_active || !user.is_docusign_completed) {
        //   navigate("/service-provider-stripe-status");
        // } else {
        //   navigate("/");
        // }
        dispatch(getAllServiceProviders());
        navigate("/service-provider-dashboard/analytics");
      } else if (user.service_provider && !user.is_provider_verified) {
        navigate("/provider-details-verification");
      } else {
        navigate("/");
      }

      // toast.success("Logged In!");
    }
    if (error) {
      toast.error(error);
    }
  }, [user, error]);

  //   Change Input Handler
  const changeInputHandler = (event) => {
    setFormData((prevState) => {
      return {
        ...prevState,
        [event.target.id]: event.target.value,
      };
    });
  };

  //   Form Submit Handler
  const submitFormHandler = (event) => {
    event.preventDefault();
    const localUser = localStorage.getItem("user")
      ? JSON.parse(localStorage.getItem("user"))
      : null;

    if (localUser !== null) {
      window.location.reload(false);
      return;
    }
    if (formData.email === "" || formData.password === "") {
      return toast.error("Both Fields are required!");
    }
    dispatch(loginUser(formData));
  };

  const [show, setShow] = useState(false);
  const [selectedRole, setSelectedRole] = useState("");

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  // const handleRoleChange = (event) => {
  //   setSelectedRole(event.target.value);
  // };
  // const handleFormSubmit = (selectedRole) => {
  //   // event.preventDefault();
  //   if (selectedRole === "user") {
  //     navigate("/sign-up");
  //   } else if (selectedRole === "service_provider") {
  //     navigate("/sign-up-storage-provider");
  //   }
  //   handleClose();
  // };

  const handleFormSubmit = (userType) => {
    return () => {
      if (userType === "user") {
        navigate("/sign-up");
      } else if (userType === "service_provider") {
        navigate("/sign-up-storage-provider");
      }
      handleClose();
    };
  };

  const [showpassword, setShowpassword] = useState(false);
  const showPassword=()=>{
    if (showpassword==true)
    {
      setShowpassword(false);
    }
    else{
      setShowpassword(true);
    }
  }

  return loading || loadingInfo ? (
    <Spinner />
  ) : (
    <>
    <div style={{
      display:'flex',
      alignItems:'center',
      justifyContent:'center',
      width: '100%',
      height: '100vh',
      backgroundColor:'#F5F6FA',
    }}>
        <div className="d-flex flex-column align-items-center justify-content-center" style={{width:'480px' , height:'434px'}}>
            <div>
              <img src="/images/blufindx.png" alt="bluefindxlogo" style={{width:'75px', height:'39px', marginBottom:'16px',}}></img>
            </div>
            <div className="text-center">
              <p className="a-font mb-0" 
            style={{color:'#202224',
              fontSize:'32px',
              fontWeight:700,
            }}
            >Welcome Back.</p>
            <p className="a-font" 
            style={{color:'#2F2F2F',
              fontSize:'16px',
              fontWeight:400,
            }}
            >Lets sign in to your account and get started.</p>
            </div>
            
            <div className="">
                  <form onSubmit={submitFormHandler}>
                    <div style={{width:'480px' ,marginBottom:'16px',}}>
                      <label htmlFor="email" className="a-font login-label">Email Address</label>
                      <div className="d-flex login-input gap-2 align-items-center ps-2"  style={{borderRadius:'8px'}}  >
                      <MdOutlineMailOutline size={20} />
                      <input
                          type="email"
                          name="email"
                          id="email"
                          value={formData.email}
                          onChange={changeInputHandler}
                          className=""
                          placeholder=""
                          style={{width:'100%',height:'100%' ,border:'none' ,borderTopRightRadius:'8px' , borderBottomRightRadius:'8px'}}                      
                        />
                      </div>
                    </div>
                    <div style={{width:'480px',marginBottom:'32px',}}>
                      <label htmlFor="password" className="a-font login-label">Password</label>
                      <div className="d-flex login-input gap-2 align-items-center ps-2"  style={{borderRadius:'8px'}}  >
                      <RiLock2Line size={20} />
                      <input
                            // type="password"
                            type={showpassword ? "text" : "password"} 
                            name="password"
                            id="password"
                            value={formData.password}
                            onChange={changeInputHandler}
                            placeholder=""
                            style={{width:'100%',height:'100%' ,border:'none' ,borderTopRightRadius:'8px' , borderBottomRightRadius:'8px'}}
                          /> 
                          {showpassword 
                          ? 
                          <IoEyeSharp size={20} style={{marginRight:'10px', cursor:'pointer'}} onClick={showPassword}/>
                        :<IoEyeOff size={20} style={{marginRight:'10px', cursor:'pointer'}} onClick={showPassword}></IoEyeOff>}
                          
                          
                      </div>
                          <div className="d-flex justify-content-end mt-2">
                            {/* <p className="login-label">
                              <MdCheckBox size={20}/> Remember For 30 Days</p> */}
                            <Link
                              to="/forgot-password"
                              className="nav-link text-dark text-center"
                            >
                              <p className="login-label" style={{color:'#ffcc00'}}>Forgot Password</p>
                            </Link>
                            </div>
                    </div>
                    <div className="form-group">
                    <button
                      type="submit"
                      style={{
                        backgroundColor: '#ffcc00',
                        border: 'none',
                        borderRadius: '8px',
                        width: '100%',
                        height: '39px',
                        color: '#202224',
                        marginBottom: '16px',
                        display: 'flex',
                        alignItems: 'center', 
                        justifyContent: 'center', 
                      }}
                    >
                      <span
                      className="a-font"
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          fontSize: '16px',
                          fontWeight: 600,
                          gap: '6px',
                        }}
                      >
                        Sign In <PiSignIn size={18} />
                      </span>
                    </button>
                  </div>

                  {/* <div className="form-group">
                    <button
                      type="submit"
                      className="login-input text-center"
                      style={{
                        borderRadius: '8px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        padding: '10px',
                      }}
                    >
                      <FcGoogle size={20} style={{ marginRight: '8px' }} />
                      <p
                        style={{
                          fontSize: '16px',
                          fontWeight: 600,
                          margin: 0,
                        }}
                      >
                        Sign In With Google
                      </p>
                    </button>
                  </div> */}

                </form>
                <Link to="/sign-up" className="nav-link text-dark text-center m-3">
                    <p className="a-font" style={{
                      color: '#202224',
                      fontSize:'14px',
                      fontWeight:600,
                    }}>
                       Don't Have an account? 
                      <span style={{color:'#ffcc00',}}> Sign Up</span></p>
                </Link>
            </div>
        </div>
      </div>
      {/* <div className="card mx-auto mt-5 authWidth w-50 bg-light">
        <div className="card-body">
          <h1
            className="text-center mineTextOrange "
            style={{ backgroundColor: "#000034", padding: "10px" }}
          >
            <img src="/images/FindxStorage3.png" height={40} />
          </h1>
          <h2 className="text-center mb-3">Sign In</h2>
          <div className="border-bottom"></div>
          <form onSubmit={submitFormHandler}>
            <div className="form-group m-3">
              <input
                type="email"
                name="email"
                id="email"
                value={formData.email}
                onChange={changeInputHandler}
                className="form-control"
                placeholder="Email"
              />
            </div>
            <div className="form-group m-3">
              <input
                type="password"
                name="password"
                id="password"
                value={formData.password}
                className="form-control"
                onChange={changeInputHandler}
                placeholder="Password"
              />
            </div>
            <div className="form-group m-3">
              <button
                type="submit"
                className="border-0 p-2 bg-orange text-dark d-block w-100"
              >
                Sign In
              </button>
            </div>
            <div className="border-bottom"></div>
          </form>
          <Link
            to="#"
            className="nav-link text-dark text-center m-3"
            onClick={handleShow}
          >
            Don't Have an Account? Create New!
          </Link>
          <Modal show={show} onHide={handleClose} centered>
            <div className="modal-content">
              <div className="modal-header">
                <h1 className="modal-title fs-5" id="exampleModalToggleLabel">
                  Select Role
                </h1>
              </div>
              <div className="flex container row">
                <div className="col-md-6">
                  <div className="card">
                    <div
                      className="border-0 p-3 text-dark hover:bg-orange-500"
                      onClick={() => handleFormSubmit("user")()}
                    >
                      <h5 className=" text-center card-title">User</h5>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="card">
                    <div
                      className="border-0 p-3 text-dark"
                      onClick={() => handleFormSubmit("service_provider")()}
                    >
                      <h5 className="card-title">Service Provider</h5>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer"></div>
            </div>
          </Modal>

          <Link
            to="/forgot-password"
            className="nav-link text-dark text-center m-3"
          >
            Forgot Password?
          </Link>
        </div>
      </div> */}
    </>
  );
};

export default SignIn;
