import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { FaBell } from "react-icons/fa";
import { Dropdown } from "react-bootstrap";
import axios from "axios";

import useNotifications from "../../hooks/useNotications";
import "./notification.css";

const Notification = () => {
  const { user } = useSelector((state) => state.userLogin);
  let accessToken = user && user.access ? user.access : "";

  const user_id = localStorage.getItem("user_id");
  const [isOpen, setIsOpen] = useState(false);
  const { notifications } = useNotifications(user_id);
  // Making a copy and reversing the order
  // const reversedNotifications = notifications.slice().reverse();

  // notifications.length = 0;

  // Function to extract initials
  const getInitials = (name) => {
    return name
      .split(" ")
      .slice(0, 2)
      .map((word) => word[0])
      .join("")
      .toUpperCase();
  };

  // Error handler for image loading failure
  const handleImageError = (e, title) => {
    e.target.onerror = null; // Prevents future triggers
    e.target.style.display = "none"; // Hides the image element
    e.target.nextElementSibling.innerText = getInitials(title); // Sets the initials
    e.target.nextElementSibling.style.display = "block"; // Shows the initials
  };

  useEffect(() => {
    if (isOpen) {
      notifications?.map((value, index) => {
        if (value.is_read === false) {
          const url = `https://have-a-seatnow.com/app/notifications/update-notification/${value.id}/`;
          const data = {
            is_read: true,
          };

          const headers = {
            Authorization: `Bearer ${accessToken}`,
          };

          axios
            .patch(url, data, { headers })
            .then((response) => {
              console.log(response.data);
            })
            .catch((error) => {
              console.error("Error:", error);
            });
        }
      });
    }
  }, [isOpen]);

  return (
    <div className="notification-container">
      <Dropdown show={isOpen} onToggle={() => setIsOpen(!isOpen)} align="end">
        <Dropdown.Toggle
          variant="link"
          id="notification-icon"
          className="notification-icon-btn text-dark"
        >
          {/* <FaBell /> */}

          <svg width="28" height="30" viewBox="0 0 28 30" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M10.8833 0.761719C8.59037 0.761719 6.66409 2.48583 6.41087 4.76477L5.16518 15.976H1.59375C0.765323 15.976 0.09375 16.6476 0.09375 17.476V19.5474C0.09375 20.3759 0.765324 21.0474 1.59375 21.0474H25.6414C26.4698 21.0474 27.1414 20.3759 27.1414 19.5474V17.476C27.1414 16.6476 26.4698 15.976 25.6414 15.976H22.0699L20.8242 4.76478C20.571 2.48583 18.6447 0.761719 16.3518 0.761719H10.8833Z" fill="#FFCC35"/>
        <rect x="10.2363" y="22.738" width="6.7619" height="6.7619" rx="2.25" fill="white"/>
        </svg>
          {notifications[0]?.unread_notifications > 0 && (
            <span className="notification-badge a-font">
              {notifications[0]?.unread_notifications}
            </span>
          )}
        </Dropdown.Toggle>
        <Dropdown.Menu className="notification-dropdown-menu">
          {notifications.length === 0 && (
            <div className="notification-item d-flex justify-content-center m-3 text-nowrap">
              No notifications to show
            </div>
          )}
          {notifications
            .slice()
            .sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
            .map((notification, index) => (
              <Dropdown.Item
                key={index}
                className={`notification-item ${notification.is_read ? "" : "unread"}`}
              >
                {/* <img src={notification.avatarUrl} alt="" className="notification-avatar" onError={(e) => handleImageError(e, notification.title)} /> */}
                <div className="notification-initials">
                  {getInitials(notification.title)}
                </div>
                <div className="notification-details">
                  <span className="notification-user">
                    {notification.title}
                  </span>
                  <span className="notification-action">
                    {notification.body}
                  </span>
                  <div className="notification-timestamp">
                    {notification.created_at || "July 23, 2023 7:39 AM"}
                  </div>
                </div>
              </Dropdown.Item>
            ))}
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};

export default Notification;
