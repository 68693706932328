import React, { useState } from "react";
import { GiOrange } from "react-icons/gi";
import {
  MdDriveFileRenameOutline,
  MdPhone,
  MdFax,
  MdEmail,
  MdWeb,
  MdFacebook,
} from "react-icons/md";
import { FiInstagram, FiTwitter } from "react-icons/fi";
import { AiFillGoogleSquare } from "react-icons/ai";
import { useDispatch } from "react-redux";

import { toast } from "react-toastify";
import { updateServiceProviders } from "../../../actions/serviceProviderActions";
import InputMask from "react-input-mask";
import ServiceProviderDetailsForm from "./ServiceProviderDetailsForm";
import { IoCheckmark } from "react-icons/io5";

const ServiceProviderDetailsUpdateForm = ({ serviceProvider }) => {
  const [formData, setFormData] = useState({
    name: serviceProvider.name,
    phone_number: serviceProvider.phone_number,
    fax_number: serviceProvider.fax_number,
    email: serviceProvider.email,
    website: serviceProvider.website,
    facebook: serviceProvider.facebook || "",
    instagram: serviceProvider.instagram || "",
    twitter: serviceProvider.twitter || "",
    google_buisness: serviceProvider.google_buisness || "",
  });

  const dispatch = useDispatch();

  const formDataChangeHandler = (event) => {
    setFormData((prev) => {
      return {
        ...prev,
        [event.target.id]: event.target.value,
      };
    });
  };

  const [errorNumber, setError] = useState("");
  const submitFormHandler = (e) => {
    e.preventDefault();
    dispatch(updateServiceProviders(formData, serviceProvider.id));
  };

  return (
    <>
      {/* <div className="border-bottom">
        <h1
          className="text-center mineTextOrange"
          style={{ backgroundColor: "#000034", padding: "10px" }}
        >
          <img src="/images/FindxStorage3.png" height={40} />
        </h1>
        <p className="lead text-uppercase text-center">
          Please fill your data carefully to update your information.
        </p>
      </div> */}
      <form className="mt-2" onSubmit={submitFormHandler}>
        <div className="d-flex" style={{gap:'140px'}}>
        <div className="form-group">
          <p className="a-font mb-1" style={{fontSize:'16px' , fontWeight:500 , color:'#202224'}}>Name <span style={{ color: "red" }}>*</span></p>
          <div className="input-group">
            <input
              type="text"
              id="name"
              value={formData.name}
              placeholder="Enter Name"
              onChange={formDataChangeHandler}
              required
              title="Please fill in the name."
              className="px-5 p-1 n-font"
              style={{backgroundColor:'#F1F4F9' ,border:'1px solid #D8D8D8' , borderRadius:'8px' , fontSize:'14px',
                fontSize:'14px' , fontWeight:500 , color:'black'
              }}
            />
          </div>
        </div>
        <div className="form-group">
        <p className="a-font mb-1" style={{fontSize:'16px' , fontWeight:500 , color:'#202224'}}>Phone Number <span style={{ color: "red" }}>*</span></p>
          <div className="input-group">
            <InputMask
              mask="+1 (999) 999-9999"
              value={formData.phone_number}
              className="form-control"
              id="phone_number"
              placeholder="Enter Phone Number"
              onChange={(e) => {
                const formatNumber = e.target.value.replace(/[()\s-]/g, "");
                e.target.value = formatNumber;
                formDataChangeHandler(e);
              }}
              title="Please enter a valid phone number in the format +1 (999) 999-9999"
              required
            >
              {(inputProps) => (
                <input type="text" {...inputProps} 
                className="px-5 p-1 n-font"
                style={{backgroundColor:'#F1F4F9' ,border:'1px solid #D8D8D8' , borderRadius:'8px' , fontSize:'14px',
                fontSize:'14px' , fontWeight:500 , color:'black'
                }} />
              )}
            </InputMask>
          </div>
        </div>
        </div>
        <div className="d-flex py-4" style={{gap:'140px'}}>
      <div className="form-group">
        <p className="a-font mb-1" style={{fontSize:'16px' , fontWeight:500 , color:'#202224'}}>Fax Number</p>
          <div className="input-group">
            <input
              type="text"
              value={formData.fax_number}
              className="px-5 p-1 n-font"
              style={{backgroundColor:'#F1F4F9' ,border:'1px solid #D8D8D8' , borderRadius:'8px' , fontSize:'14px',
                fontSize:'14px' , fontWeight:500 , color:'black'
              }}
              id="fax_number"
              placeholder="Enter Fax Number"
              onChange={formDataChangeHandler}
            />
          </div>
        </div>
        <div className="form-group">
        <p className="a-font mb-1" style={{fontSize:'16px' , fontWeight:500 , color:'#202224'}}>Business Email</p>
          <div className="input-group">
            <input
              type="email"
              value={formData.email}
              className="px-5 p-1 n-font"
              style={{backgroundColor:'#F1F4F9' ,border:'1px solid #D8D8D8' , borderRadius:'8px' , fontSize:'14px',
                fontSize:'14px' , fontWeight:500 , color:'black'
              }}
              id="email"
              placeholder="Enter Email"
              onChange={formDataChangeHandler}
            />
          </div>
        </div>
      </div>

      <hr></hr>
      
      <p className="a-font mb-0" style={{fontSize:'22px' , fontWeight:600 , color:'#202224'}}>Connect your socials</p>

        <div className="d-flex mt-4" style={{gap:'140px'}}>
        <div className="form-group">
        <p className="a-font mb-1" style={{fontSize:'16px' , fontWeight:500 , color:'#202224'}}>Website</p>
          <div className="input-group">
            <input
              type="text"
              value={formData.website}
              className="px-5 p-1 n-font"
              style={{backgroundColor:'#F1F4F9' ,border:'1px solid #D8D8D8' , borderRadius:'8px' , fontSize:'14px',
                fontSize:'14px' , fontWeight:500 , color:'black'
              }}
              id="website"
              placeholder="Enter Website URL"
              onChange={formDataChangeHandler}
            />
          </div>
        </div>
        <div className="form-group">
        <p className="a-font mb-1" style={{fontSize:'16px' , fontWeight:500 , color:'#202224'}}>Facebook</p>
          <div className="input-group">
            <input
              type="text"
              value={formData.facebook}
              className="px-5 p-1 n-font"
              style={{backgroundColor:'#F1F4F9' ,border:'1px solid #D8D8D8' , borderRadius:'8px' , fontSize:'14px',
                fontSize:'14px' , fontWeight:500 , color:'black'
              }}
              id="facebook"
              placeholder="Enter Facebook URL"
              onChange={formDataChangeHandler}
            />
          </div>
        </div>
        </div>
        <div className="d-flex py-4" style={{gap:'140px'}}>
        <div className="form-group">
        <p className="a-font mb-1" style={{fontSize:'16px' , fontWeight:500 , color:'#202224'}}>Instagram</p>
          <div className="input-group">
            <input
              type="text"
              value={formData.instagram}
              className="px-5 p-1 n-font"
              style={{backgroundColor:'#F1F4F9' ,border:'1px solid #D8D8D8' , borderRadius:'8px' , fontSize:'14px',
                fontSize:'14px' , fontWeight:500 , color:'black'
              }}
              id="instagram"
              placeholder="Enter Instagram URL"
              onChange={formDataChangeHandler}
            />
          </div>
        </div>
        <div className="form-group">
        <p className="a-font mb-1" style={{fontSize:'16px' , fontWeight:500 , color:'#202224'}}>Twitter</p>
          <div className="input-group">
            <input
              type="text"
              value={formData.twitter}
              className="px-5 p-1 n-font"
              style={{backgroundColor:'#F1F4F9' ,border:'1px solid #D8D8D8' , borderRadius:'8px' , fontSize:'14px',
                fontSize:'14px' , fontWeight:500 , color:'black'
              }}
              id="twitter"
              placeholder="Enter Twitter URL"
              onChange={formDataChangeHandler}
            />
          </div>
        </div>
        </div>
        <div className="form-group">
        <p className="a-font mb-1" style={{fontSize:'16px' , fontWeight:500 , color:'#202224'}}>Google Business</p>
          <div className="input-group">
            <input
              type="text"
              value={formData.google_buisness}
              className="px-5 p-1 n-font"
              style={{backgroundColor:'#F1F4F9' ,border:'1px solid #D8D8D8' , borderRadius:'8px' , fontSize:'14px',
                fontSize:'14px' , fontWeight:500 , color:'black'
              }}
              id="google_buisness"
              placeholder="Enter Google Buisness URL"
              onChange={formDataChangeHandler}
            />
          </div>
        </div>
        {/* <button className="mt-2 btn  text-dark">Update Details</button> */}
        <div className="d-flex mt-5 justify-content-end">
            <button className="p-1 px-3 d-flex gap-2 align-items-center"
                  style={{
                    backgroundColor: '#FFCC00',
                    borderRadius: '6px',
                    border: 'none',
                  }}>
                  <IoCheckmark size={12} />
              Update Details
            </button>
        </div>
      </form>
    </>
  );
};

export default ServiceProviderDetailsUpdateForm;
