import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { BsCheck2 } from "react-icons/bs";
import { RxCross1 } from "react-icons/rx";
import {
  deleteOrder,
  deleteOrderAdmin,
  getAllOrders,
} from "../../../actions/ordersActions";
import { FaCircle } from "react-icons/fa";

const HomeOrdersTableUser = ({ orders }) => {
  const { info } = useSelector((state) => state.userInfo);

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const deleteOrderHandler = (id) => {
    dispatch(deleteOrderAdmin(id));
    dispatch(getAllOrders(FormData));
    navigate("/storage-provider-dashboard");
  };

  return (
    <>
      <div className="table-responsive">
        <table className="table mt-4">
          <thead className="text-center">
            <tr>
              <th className="order-table-content bg-gray p-2 text-uppercase a-font" style={{borderTopLeftRadius:'10px',borderBottomLeftRadius:'10px'}} scope="col">ID</th>
              <th className="order-table-content bg-gray p-2 text-uppercase a-font" scope="col">Business Type</th>
              <th className="order-table-content bg-gray p-2 text-uppercase a-font" scope="col">PRICE</th>
              <th className="order-table-content bg-gray p-2 text-uppercase a-font" scope="col"></th>
              <th className="order-table-content bg-gray p-2 text-uppercase a-font" style={{borderTopRightRadius:'10px',borderBottomRightRadius:'10px'}} scope="col">Payment</th>
            </tr>
          </thead>
          <tbody className="text-center">
            {orders &&
              orders?.map((order) => (
                <tr key={order?.id}>
                  <td className="order-table-bcontent p-4">{order?.slug}</td>
                  <td className="order-table-bcontent p-4">{order.business_type}</td>
                  <td className="order-table-bcontent p-4">${order.total_price}</td>

                  <td className="p-4">
                  <button
                      type="button"
                      onClick={() => navigate(`/orders/${order.id}`)}
                      className="px-3"
                      style={{
                        backgroundColor: '#ffcc00',
                        border: 'none',
                        borderRadius: '4.5px',
                        height:'28px',
                        color: '#000034',
                      }}
                    >
                      View Details
                    </button>
                  </td>

                  {!order.payment_completed ? (
                    <td className="p-4">
                      <button
                        type="button"
                        onClick={() =>
                          window.open(order.payment_link, "_blank")
                        }
                        className="px-3"
                        style={{
                          backgroundColor: '#ffcc00',
                          border: 'none',
                          borderRadius: '4.5px',
                          height:'28px',
                          color: '#000034',
                        }}
                      >
                        Payment Due
                      </button>
                    </td>
                  ) : (
                    <td>
                            <span 
                            style={{
                                fontWeight: '500',
                                fontSize: '12px',
                                backgroundColor: '#D9F7E866',
                                borderRadius: '118px',
                                color: '#5CDD9C',
                                padding: '2px 10px',
                                display: 'inline-flex',         
                                alignItems: 'center',    
                                justifyContent: 'center', 
                                border:'1px solid #5CDD9C',
                                gap:'3px',
                              }}>
                                <FaCircle size={6}></FaCircle>
                            Paid
                          </span>
                    </td>
                  )}

                  {/* {info && info.is_service_provider && (
                  <td className="p-3">
                    <button
                      className="btn btn-dark"
                      onClick={() => deleteOrderHandler(order.id)}
                    >
                      Delete
                    </button>
                  </td>
                )} */}
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default HomeOrdersTableUser;
