import React, { useState, useEffect } from "react";
import FilterAndSort from "./FilterAndSort";
import IndoorStorageHeading from "./IndoorStorageHeading";
import IndoorStorageList from "./IndoorStorageList";
import Select from "react-select";
import { Check2Square } from "react-bootstrap-icons";
import { FaRegSquareFull } from "react-icons/fa6";
import { FaArrowsDownToLine } from "react-icons/fa6";

const AvailableUnits = ({ product, storageFacility }) => {
  const [sortedUnits, setSortedUnits] = useState({
    indoor_storage: [],
    outdoor: [],
    climate_control: [],
  });

  useEffect(() => {
    const categorizedStorageUnits = {
      indoor_storage: [],
      outdoor: [],
      climate_control: [],
    };

    storageFacility.storage_unit.forEach((storageUnit) => {
      const type = storageUnit.storage_unit_type;

      if (!categorizedStorageUnits[type]) {
        categorizedStorageUnits[type] = [];
      }

      categorizedStorageUnits[type].push(storageUnit);
    });

    setSortedUnits(categorizedStorageUnits);
  }, [storageFacility.storage_unit]);


  // Filters :
  const [selectedFilters, setSelectedFilters] = useState({
      type:"",
      is_available: false,
    });
  
    const handleTypeChange = (event) => {
      const { id, value } = event.target;
      setSelectedFilters((prevState) => ({
        ...prevState,
        [id]: id === "is_available" ? value === "true" : value,
      }));
    };
    console.log("filters " , selectedFilters)
    const getFilteredUnits = () => {
      return Object.entries(sortedUnits).reduce((acc, [type, units]) => {
        
        if (selectedFilters.unit_type && selectedFilters.unit_type !== type) {
          return acc;
        }
        const filteredUnits = units.filter((unit) => {
          if (
            selectedFilters.is_available &&
            String(unit.is_available) !== String(selectedFilters.is_available)
          ) {
            return false;
          }
          return true;
        });
        if (filteredUnits.length > 0) {
          acc[type] = filteredUnits;
        }
        return acc;
      }, {});
    };
    
    

  return (
    <div className="">
      <div className="service-card p-3">
        <div
          className="d-flex align-items-center justify-content-between">
            <div className="d-flex align-items-center">
              <p className="a-font mb-0" style={{fontSize:'18px' , fontWeight:600 , color:'#000034'}}>Available Units 
                <span style={{fontSize:'14px' , fontWeight:300}}>  (all sizes are approximate)</span>
                </p>
            </div>
            <div className="d-flex align-items-center gap-3">
                <div className="d-flex align-items-center">
                    <svg width="17" height="19" viewBox="0 0 17 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M8.15 7.6C12.0988 7.6 15.3 6.12254 15.3 4.3C15.3 2.47746 12.0988 1 8.15 1C4.20116 1 1 2.47746 1 4.3C1 6.12254 4.20116 7.6 8.15 7.6Z" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                  <path d="M1 4.30005C1.00186 7.6114 3.27784 10.4879 6.5 11.2513V15.85C6.5 16.7613 7.23873 17.5 8.15 17.5C9.06127 17.5 9.8 16.7613 9.8 15.85V11.2513C13.0222 10.4879 15.2981 7.6114 15.3 4.30005" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                  </svg>
                    <p className="mb-0 ms-2" style={{fontSize:'14px', fontWeight:600,color:'#00034'}}>Filter By</p>
                </div>
                <div 
                  style={{
                    width: '0.5px',         
                    backgroundColor: '#000000', 
                    height: '42px',     
                    margin: '0 10px'    
                  }}
                ></div>
                <div className="unit-select">
                  <select
                    id="unit_type"
                    onChange={handleTypeChange}
                    className="custom-input"
                    aria-label="Default select example"
                    style={{
                      width: "fit-content",
                    }}
                    value={selectedFilters.type}
                    >
                    <option value="Select Type">Select Type</option>
                    <option value="indoor_storage">Indoor Storage </option>
                    <option value="outdoor">Outdoor Storage</option>
                    <option value="climate_control">Climate Control</option>
                  </select>
                </div>
                <div className="unit-select">
                <select
                    id="is_available"
                    onChange={handleTypeChange}
                    className="custom-input"
                    value={selectedFilters.is_available ? "true" : "false"}
                  >
                    <option value="">Select Availability</option>
                    <option value="true">Available</option>
                    <option value="false">Not Available</option>
                  </select>

                </div> 
                {/* <div className="d-flex align-items-center">
                <FaRegSquareFull size={14}/>
                <p className="a-font mb-0 ml-2" style={{fontSize:'14px' , fontWeight:500  , color:'#202224'}}> Price </p>
                <FaArrowsDownToLine size={14}/>
                </div>
                <div className="d-flex align-items-center">
                <FaRegSquareFull size={14}/>
                <p className="a-font mb-0 ml-2" style={{fontSize:'14px' , fontWeight:500  , color:'#202224'}}> Promotions</p>
                </div> */}
            </div>
        </div>
      </div>
      {storageFacility?.storage_unit?.length === 0 ? (
        <div className="alert alert-danger" role="alert">
          This Storage Facility has no available Units.
        </div>
      ) : (
        <>
          {Object.entries(getFilteredUnits()).map(
            ([type, units]) =>
              units.length > 0 && (
                <div key={type} className="service-card p-5 mt-4">

                    {units.map((storageType , index) => (
                      <div key={storageType.id} i={index}>
                        <IndoorStorageList room={storageType} />{" "}
                          {index === units.length-1 ? <></> : <hr></hr>}
                      </div>
                    ))}
                </div>
              ),
          )}
        </>
      )}
    </div>
  );
};

export default AvailableUnits;
