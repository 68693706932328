import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { GiOrange } from "react-icons/gi";
import { useNavigate } from "react-router-dom";

import { useSelector, useDispatch } from "react-redux";
import { registerUser } from "../../../actions/userActions";
import { toast } from "react-toastify";
import Spinner from "../../../shared-components/Spinner/Spinner";
import { USER_REGISTER_RESET } from "../../../constants/userConstants";
import { MdOutlineMailOutline } from "react-icons/md";
import { RiLock2Line } from "react-icons/ri";
import { GrPrevious } from "react-icons/gr";
import { PiSignOut} from "react-icons/pi";
import { MdCheckBox } from "react-icons/md";
import { FcGoogle } from "react-icons/fc";
import { AiOutlineUser } from "react-icons/ai";
import { IoEyeSharp } from "react-icons/io5";
import { IoEyeOff } from "react-icons/io5";

const SignUp = () => {
  const [formData, setFormData] = useState({
    firstname: "",
    lastname:"",
    email: "",
    password: "",
    is_service_provider: false,
  });

  const { message, error, loading } = useSelector(
    (state) => state.userRegister,
  );
  const { user } = useSelector((state) => state.userLogin);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  // USE EFFECT

  useEffect(() => {
    if (user) {
      navigate("/");
    }
    if (message) {
      navigate("/sign-in");
      toast.success(message);
    }

    if (error) {
      toast.error(error);
      dispatch({ type: USER_REGISTER_RESET });
    }
  }, [message, error, user]);

  //   Change Input Handler
  const changeInputHandler = (event) => {
    setFormData((prevState) => {
      return {
        ...prevState,
        [event.target.id]: event.target.value,
      };
    });
  };

  //   Form Submit Handler
  const submitFormHandler = (event) => {
    event.preventDefault();
    if (formData.email === "" || formData.password === "") {
      return toast.error("Both Feilds are required!");
    }
    if (formData.password.length <= 8) {
      return toast.error("Password Must be Greater than 8 Characters");
    }
    dispatch(registerUser(formData));
  };

  const [showpassword, setShowpassword] = useState(false);
    const showPassword=()=>{
      if (showpassword==true)
      {
        setShowpassword(false);
      }
      else{
        setShowpassword(true);
      }
    }

  return loading ? (
    <Spinner />
  ) : (
    <>
   <div style={{
      display:'flex',
      alignItems:'center',
      justifyContent:'center',
      width: '100%',
      height: '100vh',
      backgroundColor:'#F5F6FA',
    }}>
        <div className="d-flex flex-column align-items-center justify-content-center" style={{width:'480px' , height:'434px'}}>
            <div>
              <img src="/images/blufindx.png" alt="bluefindxlogo" style={{width:'75px', height:'39px', marginBottom:'16px',}}></img>
            </div>
            <div className="text-center">
              <p className="a-font mb-0" 
            style={{color:'#202224',
              fontSize:'32px',
              fontWeight:700,
            }}
            >Let’s Create Your Account.</p>
            <p className="a-font" 
            style={{color:'#2F2F2F',
              fontSize:'16px',
              fontWeight:400,
            }}
            >Sign up for free and get started quickly.</p>
            </div>
            <div
                className="d-flex align-items-center justify-content-between p-2"
                style={{
                  width: '348px',
                  backgroundColor: '#ECEDF1',
                  height: '45px',
                  borderRadius: '8px',
                  border: '0.5px solid #97979799',
                }}
              >
                <Link
                  to="/sign-up-storage-provider"
                  style={{
                    textDecoration: 'none',
                    color: '#202224',
                  }}
                >
                  <p
                  className="a-font p-3"
                    style={{
                      color: '#202224',
                      fontSize: '16px',
                      fontWeight: 600,
                      margin: 0,
                    }}
                  >
                    Storage Provider
                  </p>
                </Link>
                <Link
                  to="/sign-up"
                  style={{
                    textDecoration: 'none',
                  }}
                >
                  <div
                    className="px-3 p-1 a-font"
                    style={{
                      backgroundColor: '#ffcc00',
                      border: 'none',
                      borderRadius: '8px',
                      color: '#202224',
                      fontSize: '16px',
                      fontWeight: 600,
                      display: 'inline-block',
                    }}
                  >
                    Storage Renter
                  </div>
                </Link>
              </div>

            <div className="">
                  <form onSubmit={submitFormHandler}>
                  {/* <div style={{width:'480px' ,marginBottom:'16px',}}>
                      <label htmlFor="email" className="a-font login-label">Username</label>
                      <div className="d-flex login-input gap-2 align-items-center ps-2"  style={{borderRadius:'8px'}}  >
                      <AiOutlineUser size={20} />
                      <input
                          type="email"
                          name="email"
                          id="email"
                          value={formData.email}
                          onChange={changeInputHandler}
                          className=""
                          placeholder=""
                          style={{width:'100%',height:'100%' ,border:'none' ,borderTopRightRadius:'8px' , borderBottomRightRadius:'8px'}}                      
                        />
                      </div>
                    </div> */}
                    <div style={{width:'480px' ,marginBottom:'16px',}}>
                      <label htmlFor="firstname" className="a-font login-label">First Name</label>
                      <div className="d-flex login-input gap-2 align-items-center ps-2"  style={{borderRadius:'8px'}}  >
                      <MdOutlineMailOutline size={20} />
                      <input
                          type=""
                          name="firstname"
                          id="firstname"
                          value={formData.firstname}
                          onChange={changeInputHandler}
                          className=""
                          placeholder=""
                          style={{width:'100%',height:'100%' ,border:'none' ,borderTopRightRadius:'8px' , borderBottomRightRadius:'8px'}}                      
                        />
                      </div>
                    </div>
                    <div style={{width:'480px' ,marginBottom:'16px',}}>
                      <label htmlFor="lastname" className="a-font login-label">Last Name</label>
                      <div className="d-flex login-input gap-2 align-items-center ps-2"  style={{borderRadius:'8px'}}  >
                      <MdOutlineMailOutline size={20} />
                      <input
                          type=""
                          name="lastname"
                          id="lastname"
                          value={formData.lastname}
                          onChange={changeInputHandler}
                          className=""
                          placeholder=""
                          style={{width:'100%',height:'100%' ,border:'none' ,borderTopRightRadius:'8px' , borderBottomRightRadius:'8px'}}                      
                        />
                      </div>
                    </div>
                    <div style={{width:'480px' ,marginBottom:'16px',}}>
                      <label htmlFor="email" className="a-font login-label">Email Address</label>
                      <div className="d-flex login-input gap-2 align-items-center ps-2"  style={{borderRadius:'8px'}}  >
                      <MdOutlineMailOutline size={20} />
                      <input
                          type="email"
                          name="email"
                          id="email"
                          value={formData.email}
                          onChange={changeInputHandler}
                          className=""
                          placeholder=""
                          style={{width:'100%',height:'100%' ,border:'none' ,borderTopRightRadius:'8px' , borderBottomRightRadius:'8px'}}                      
                        />
                      </div>
                    </div>
                    <div style={{width:'480px',marginBottom:'32px',}}>
                      <label htmlFor="password" className="a-font login-label">Password</label>
                      <div className="d-flex login-input gap-2 align-items-center ps-2"  style={{borderRadius:'8px'}}  >
                      <RiLock2Line size={20} />
                      <input
                            type={showpassword ? "text" : "password"} 
                            name="password"
                            id="password"
                            value={formData.password}
                            onChange={changeInputHandler}
                            placeholder=""
                            style={{width:'100%',height:'100%' ,border:'none' ,borderTopRightRadius:'8px' , borderBottomRightRadius:'8px'}}
                          /> 
                          {showpassword 
                          ? 
                          <IoEyeSharp size={20} style={{marginRight:'10px', cursor:'pointer'}} onClick={showPassword}/>
                          :<IoEyeOff size={20} style={{marginRight:'10px', cursor:'pointer'}} onClick={showPassword}></IoEyeOff>}
                      </div>
                    </div>
                    <div className="form-group">
                    <button
                      type="submit"
                      style={{
                        backgroundColor: '#ffcc00',
                        border: 'none',
                        borderRadius: '8px',
                        width: '100%',
                        height: '39px',
                        color: '#202224',
                        marginBottom: '16px',
                        display: 'flex',
                        alignItems: 'center', 
                        justifyContent: 'center', 
                      }}
                    >
                      <span
                      className="a-font"
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          fontSize: '16px',
                          fontWeight: 600,
                          gap: '6px',
                        }}
                      >
                        Sign Up <PiSignOut size={18} />
                      </span>
                    </button>
                  </div>

                  {/* <div className="form-group">
                    <button
                      type="submit"
                      className="login-input text-center"
                      style={{
                        borderRadius: '8px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        padding: '10px',
                      }}
                    >
                      <FcGoogle size={20} style={{ marginRight: '8px' }} />
                      <p
                       className="a-font"
                        style={{
                          fontSize: '16px',
                          fontWeight: 600,
                          margin: 0,
                        }}
                      >
                        Sign Up With Google
                      </p>
                    </button>
                  </div> */}

                </form>
                <Link to="/sign-in" className="nav-link text-dark text-center m-3">
                    <p className="a-font" style={{
                      color: '#202224',
                      fontSize:'14px',
                      fontWeight:600,
                    }}>
                       Already have an account? 
                      <span style={{color:'#ffcc00',}}> Sign In</span></p>
                </Link>
            </div>
        </div>
      </div>
      {/* <div className="card m-auto  my-5 w-50 authWidth bg-light">
        <div className="card-body">
          <h1
            className="text-center mineTextOrange "
            style={{ backgroundColor: "#000034", padding: "10px" }}
          >
            <img src="/images/FindxStorage3.png" height={40} />
          </h1>
          <h2 className="text-center mb-3">Sign Up As User</h2>
          <div className="border-bottom"></div>
          <form onSubmit={submitFormHandler}>
            <div className="form-group m-3">
              <input
                type="email"
                name="email"
                id="email"
                onChange={changeInputHandler}
                value={formData.email}
                className="form-control"
                placeholder="Email"
              />
            </div>
            <div className="form-group m-3">
              <input
                type="password"
                name="password"
                id="password"
                value={formData.password}
                className="form-control"
                onChange={changeInputHandler}
                placeholder="Password"
              />
            </div>
            <div className="form-group m-3">
              <button
                type="submit"
                className="border-0 p-2 bg-orange text-dark d-block w-100"
              >
                Sign Up
              </button>
            </div>
            <div className="border-bottom"></div>
          </form>
          <Link to="/sign-in" className="nav-link text-dark text-center m-3">
            Already have an account? Login In
          </Link>

          <Link
            to="/forgot-password"
            className="nav-link text-dark text-center m-3"
          >
            Forgot Password?
          </Link>

        </div>
      </div> */}
    </>
  );
};

export default SignUp;
