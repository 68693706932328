import React from "react";
import Rating from "../../../shared-components/RatingStars/RatingStars";
import { FaPhone, FaRegClock, FaLocationArrow } from "react-icons/fa";
import { MdFilterFrames } from "react-icons/md";
import { useRef } from "react";
import ImageModal from "../../../shared-components/PhotoGallery/PhotoModal";
import HoursModal from "./HoursModal";
import MapModal from "./MapModal/MapModal";
import { FaLocationDot } from "react-icons/fa6";


const NameAndPictureDetails = ({ product, storageFacility }) => {
  const imageModalRef = useRef(null);

  const showImageModal = () => {
    if (imageModalRef.current) {
      imageModalRef.current.showModal();
    }
  };

  console.log(storageFacility);
  return (
    <div className="d-flex col-12 justify-content-between flex-row flex-wrap"
    style={{
      borderBottomWidth: '3px',
      borderBottomStyle: 'solid',
      borderBottomColor: '#CECECE', 
    }} 
    >
      <div className="col-6 my-auto">
        <div className="d-flex gap-5 align-items-center" >
        <p className="a-font mb-0" style={{fontSize:'42px' , fontWeight:700 ,color:'#202224'}}>{storageFacility.name}</p>
        <Rating
          value={storageFacility?.average_rating}
          text={`${
            storageFacility?.total_persons_rating
              ? storageFacility?.total_persons_rating
              : 0
          } Reviews`}
        />
        </div>
        <p
        className="a-font mb-0 mt-4" style={{fontSize:'32px' , fontWeight:500 ,color:'#202224'}}>
          <FaLocationDot size={20}/>
          {storageFacility?.location.address}
        </p>
        {/* <p className="pl-3"><span className="fw-bold">Address : </span>{storageFacility.address}</p> */}
        <div className="d-flex flex-wrap mt-4">
          <span
            className="me-5 pointer d-flex align-items-center units-info-p a-font"
            style={{
              gap: "10px",
            }}
          >
            <a 
              href={`tel:${storageFacility?.storage_provider.phone_number}`}
              style={{ textDecoration: 'none', color: 'inherit' }}
            >
            <FaPhone className="mineTextOrange " />
            <span className="pl-2 units-info-p a-font mb-0">      {storageFacility?.storage_provider.phone_number}</span>
            </a>
          </span>
          <div
            className="me-5 pointer d-flex align-items-center"
            data-bs-toggle="modal"
            data-bs-target="#hoursModal"
            style={{
              gap: "10px",
            }}
          >
            <FaRegClock className="mineTextOrange" />
            <span className="pl-2 units-info-p a-font">Office Hours</span>
          </div>
          <div
            className="me-5 pointer  d-flex align-items-center"
            data-bs-toggle="modal"
            data-bs-target="#mapModal"
            style={{
              gap: "10px",
            }}
          >
            <FaLocationArrow className="mineTextOrange" />
            <span className="ml-1 units-info-p a-font">Get Directions</span>
          </div>
        </div>
      </div>

      {/* <div
        className="mt-3 d-flex flex-column"
        style={{ textAlign: "center" }}
        onClick={showImageModal}
      >
        <img
          src={
            storageFacility?.facility_images[0]
              ? storageFacility?.facility_images[0].image
              : "/images/small.png"
          }
          className="img-fluid rounded"
          alt="storagePic"
          style={{
            width: 200,
            height: 200,
            cursor: "pointer",
          }}
        />
        <ImageModal ref={imageModalRef} storageFacility={storageFacility} />
      </div> */}
        {/* <div className="d-flex col-6 p-4 justify-content-center" >
            <div id="imageCarousel" className="carousel slide" data-bs-ride="carousel" style={{
              width:'500px',
              height:'300px',
            }} >
              <div className="carousel-indicators">
                {storageFacility?.facility_images?.map((_, index) => (
                  <button
                    type="button"
                    key={index}
                    data-bs-target="#imageCarousel"
                    data-bs-slide-to={index}
                    className={index === 0 ? "active" : ""}
                    aria-current={index === 0 ? "true" : ""}
                    aria-label={`Slide ${index + 1}`}
                  ></button>
                ))}
              </div>

              <div className="carousel-inner" style={{height:'300px'}}>
                {storageFacility?.facility_images?.map((item, index) => (
                  <div
                    key={index}
                    className={`carousel-item ${index === 0 ? "active" : ""}`}
                  >
                    <img
                    style={{
                      width:'500px',
                      height:'300px',
                      objectFit:'cover',
                      borderRadius:'20px'
                    }}
                      src={item.image}
                      className="d-block w-100"
                      alt={`Slide ${index + 1}`}
                    />
                  </div>
                ))}
              </div>

              <button
                className="carousel-control-prev"
                type="button"
                data-bs-target="#imageCarousel"
                data-bs-slide="prev"
              >
                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Previous</span>
              </button>
              <button
                className="carousel-control-next"
                type="button"
                data-bs-target="#imageCarousel"
                data-bs-slide="next"
              >
                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Next</span>
              </button>
            </div>
          </div> */}


      {storageFacility?.facility_images?.length >0 ? 
      (
        <div className="d-flex col-6 p-4 justify-content-center" >
            <div id="imageCarousel" className="carousel slide" data-bs-ride="carousel" style={{
              width:'500px',
              height:'300px',
            }} >
              <div className="carousel-indicators">
                {storageFacility?.facility_images?.map((_, index) => (
                  <button
                    type="button"
                    key={index}
                    data-bs-target="#imageCarousel"
                    data-bs-slide-to={index}
                    className={index === 0 ? "active" : ""}
                    aria-current={index === 0 ? "true" : ""}
                    aria-label={`Slide ${index + 1}`}
                  ></button>
                ))}
              </div>

              <div className="carousel-inner" style={{height:'300px'}}>
              {storageFacility?.facility_images?.map((item, index) => (
                  <div
                    key={index}
                    className={`carousel-item ${index === 0 ? "active" : ""}`}
                  >
                    <img
                    style={{
                      width:'500px',
                      height:'300px',
                      objectFit:'cover',
                      borderRadius:'20px'
                    }}
                      src={item.image || item}
                      className="d-block w-100"
                      alt={`Slide ${index + 1}`}
                    />
                  </div>
                ))}
              </div>

              <button
                className="carousel-control-prev"
                type="button"
                data-bs-target="#imageCarousel"
                data-bs-slide="prev"
              >
                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Previous</span>
              </button>
              <button
                className="carousel-control-next"
                type="button"
                data-bs-target="#imageCarousel"
                data-bs-slide="next"
              >
                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Next</span>
              </button>
            </div>
          </div>
      ) :(
      <div className="d-flex col-6 p-4 justify-content-center" >
            <div id="imageCarousel" className="carousel slide" data-bs-ride="carousel" style={{
              width:'500px',
              height:'300px',
            }} >
              <div className="carousel-indicators">
                {(storageFacility?.facility_images?.length > 0 ? storageFacility.facility_images : ["/images/storage1.jpg", "/images/storage2.jpg", "/images/storage3.jpg"]).map((_, index) => (
                  <button
                    type="button"
                    key={index}
                    data-bs-target="#imageCarousel"
                    data-bs-slide-to={index}
                    className={index === 0 ? "active" : ""}
                    aria-current={index === 0 ? "true" : ""}
                    aria-label={`Slide ${index + 1}`}
                  ></button>
                ))}
              </div>

              <div className="carousel-inner" style={{height:'300px'}}>
              {(storageFacility?.facility_images?.length > 0 ? storageFacility.facility_images : ["/images/storage1.jpg","/images/storage2.jpg","/images/storage3.jpg"]).map((item, index) => (
                  <div
                    key={index}
                    className={`carousel-item ${index === 0 ? "active" : ""}`}
                  >
                    <img
                    style={{
                      width:'500px',
                      height:'300px',
                      objectFit:'cover',
                      borderRadius:'20px'
                    }}
                      src={item.image || item}
                      className="d-block w-100"
                      alt={`Slide ${index + 1}`}
                    />
                  </div>
                ))}
              </div>

              <button
                className="carousel-control-prev"
                type="button"
                data-bs-target="#imageCarousel"
                data-bs-slide="prev"
              >
                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Previous</span>
              </button>
              <button
                className="carousel-control-next"
                type="button"
                data-bs-target="#imageCarousel"
                data-bs-slide="next"
              >
                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Next</span>
              </button>
            </div>
          </div>)
          } 
      <MapModal storageFacility={storageFacility} />
      <HoursModal storageFacility={storageFacility} />
    </div>
  );
};

export default NameAndPictureDetails;
