import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Container from "../../shared-components/container/Container";
import Spinner from "../../shared-components/Spinner/Spinner";
import { getAllOrders, getAllOrdersAdmin } from "../../actions/ordersActions";
import { IoIosSkipBackward, IoIosSkipForward } from "react-icons/io";
import HomeOrdersTableUser from "../Home/HomeOrdersTable/HomeOrdersTableUser";
import HomeOrdersTableServiceProvider from "../Home/HomeOrdersTableServiceProvider/HomeOrdersTableServiceProvider";
import Pagination from "react-js-pagination"; // Import react-js-pagination
import "bootstrap/dist/css/bootstrap.min.css"; // Import Bootstrap CSS
import { FaSearch } from "react-icons/fa";

const Orders = () => {
  const { info } = useSelector((state) => state.userInfo);
  const allOrdersAdmin = useSelector((state) => state.allOrdersAdmin);
  const allOrders = useSelector((state) => state.allOrders);
  const isServiceProvider = info && info.is_service_provider;
  const order = isServiceProvider ? allOrdersAdmin : allOrders;
  const { orders, loading } = order;
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({
    search: "",
    business_type: "",
    page: 1,
  });

  useEffect(() => {
    if (isServiceProvider) {
      dispatch(getAllOrdersAdmin());
    } else {
      dispatch(getAllOrders(formData));
    }
  }, [dispatch, formData.page]);

  const handleSearchTextChange = (e) => {
    setFormData({
      ...formData,
      search: e.target.value,
    });
  };

  const handleBusinessTypeChange = (e) => {
    setFormData({
      ...formData,
      business_type: e.target.value,
    });
  };

  const handleFilter = () => {
    dispatch(getAllOrders({ ...formData, page: 1 }));
  };

  const handlePagination = (pageNumber) => {
    setFormData({
      ...formData,
      page: pageNumber,
    });
  };
   console.log("reserved orders data " , orders )

  return (
    <div className="py-5 " style={{ backgroundColor: "rgb(242, 237, 243)" }}>
      <Container>
        <p className="a-font" style={{fontSize:'32px' , fontWeight:700 , color:'#202224'}}>Reserved Units</p>
        {loading ? (
          <Spinner />
        ) : (
          <>
            {orders ? (
              orders?.length === 0 ? (
                <h5 className="text-center">You have no Orders</h5>
              ) : (
                <>
                  {isServiceProvider ? (
                    <HomeOrdersTableServiceProvider
                      orders={allOrdersAdmin.orders}
                    />
                  ) : (
                    <div className="service-card p-4 mt-4 ">
                      <div className="card-body">
                        <div
                          className="d-flex align-items-center justify-content-between"
                        >
                           <div>
                            <p className="service-card-heads a-font mb-0">Reservation Details</p>
                            </div>
                          <div className="d-flex align-items-center gap-3">
                          <div className="d-flex align-items-center">
                     <svg width="17" height="19" viewBox="0 0 17 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M8.15 7.6C12.0988 7.6 15.3 6.12254 15.3 4.3C15.3 2.47746 12.0988 1 8.15 1C4.20116 1 1 2.47746 1 4.3C1 6.12254 4.20116 7.6 8.15 7.6Z" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M1 4.30005C1.00186 7.6114 3.27784 10.4879 6.5 11.2513V15.85C6.5 16.7613 7.23873 17.5 8.15 17.5C9.06127 17.5 9.8 16.7613 9.8 15.85V11.2513C13.0222 10.4879 15.2981 7.6114 15.3 4.30005" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                      <p className="mb-0 ms-2" style={{fontSize:'14px', fontWeight:600,color:'#00034'}}>Filter By</p>
                  </div>
                  <div 
                    style={{
                      width: '0.5px',         
                      backgroundColor: '#000000', 
                      height: '42px',     
                      margin: '0 10px'    
                    }}
                  ></div>
                          <div className="input-order">
                            <select
                              id="business_type"
                              onChange={handleBusinessTypeChange}
                              className="custom-input"
                              aria-label="Default select example"
                              style={{
                                width: "fit-content",
                              }}
                              value={formData.business_type}
                            >
                              <option value="Select Type">Select Type</option>
                              <option value="commercial">Commercial</option>
                              <option value="public">Public</option>
                              <option value="business">Business</option>
                              <option value="personal">Personal</option>
                            </select>
                          </div>
                          <div className="d-flex align-items-center gap-2">
                          <div className="input-order">
                          <input
                            type="text"
                            className="custom-input"
                            placeholder="Search..."
                            value={formData.search}
                            onChange={handleSearchTextChange}
                              />
                            
                          </div>
                          <button
                              type="button"
                              onClick={handleFilter}
                              className=""
                              style={{
                                backgroundColor: '#ffcc00',
                                border: 'none',
                                borderRadius: '4.5px',
                                width:'30px',
                                height:'28px',
                                color: '#000034',
                              }}
                            >
                              <FaSearch size={14} />
                            </button>
                        </div>
                          {/* <div className="" style={{ width: "150px" }}>
                            <label className="fw-bold">Search Text:</label>
                            <input
                              type="text"
                              className="border border-1 rounded p-2"
                              style={{
                                maxWidth: "150px",
                                height: "35px",
                              }}
                              value={formData.search}
                              onChange={handleSearchTextChange}
                            />
                          </div>

                          <button
                            className="btn text-dark"
                            onClick={handleFilter}
                          >
                            Search
                          </button> */}
                          </div>
                        </div>
                        <HomeOrdersTableUser orders={orders.results} />
                      </div>
                      <div
                        className="d-flex justify-content-center align-items-center mb-5"
                        style={{
                          gap: "50px",
                        }}
                      >
                        <Pagination
                          activePage={formData.page}
                          itemsCountPerPage={10} // Number of items per page
                          totalItemsCount={orders?.count || 1} // Total number of items
                          pageRangeDisplayed={5} // Number of pages to display in the pagination
                          onChange={handlePagination} // Callback function to handle page changes
                          itemClass="page-item text-dark" // Bootstrap class for each pagination item
                          linkClass="page-link text-dark" // Bootstrap class for pagination links
                        />
                      </div>
                    </div>
                  )}
                </>
              )
            ) : (
              <p className="p-3  text-black rounded">No Orders</p>
            )}
          </>
        )}
      </Container>
    </div>
  );
};

export default Orders;
