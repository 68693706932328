import React, { memo, useEffect , useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addItemsToCart } from "../../../actions/cartActions";
import { toast } from "react-toastify";
import { BsCartPlus } from "react-icons/bs";
import { BsCurrencyDollar } from "react-icons/bs";
import { CART_ADD_ITEM_RESET } from "../../../constants/cartConstants";
import { OverlayTrigger, Tooltip ,Modal, Button } from "react-bootstrap";
import { IoCheckmark } from "react-icons/io5";
import { FaCircle } from "react-icons/fa";
import { IoMdCheckmark } from "react-icons/io";
import { MdOutlineShoppingCartCheckout } from "react-icons/md";
import { useNavigate } from "react-router-dom";

const IndoorStorageList = memo(({ room }) => {
  const dispatch = useDispatch();
  const navigate=useNavigate();
  const { error, cartItem } = useSelector((state) => state.cartAddItem);
  const { user } = useSelector((state) => state.userLogin);
  const addToCart = (roomId) => {
    toast.success("Added to cart");
    dispatch(addItemsToCart(roomId));
    const updatedCart = { ...addedToCart, [roomId]: true };
    setAddedToCart(updatedCart);
    localStorage.setItem('cartItems', JSON.stringify(updatedCart)); 
  
  };

  useEffect(() => {
    if (cartItem) {
      dispatch({ type: CART_ADD_ITEM_RESET });
    }

    if (error) {
      toast.error("Please login first to add items to the cart.");
      dispatch({ type: CART_ADD_ITEM_RESET });
    }
  }, [cartItem, error]);

  const [showModal, setShowModal] = useState(false);
  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleShowModal = () => {
    console.log("Opening modal...");
    setShowModal(true); 
  };
  
  const [addedToCart, setAddedToCart] = useState({});

  // Load cart items from localStorage
  useEffect(() => {
    const savedCart = JSON.parse(localStorage.getItem('cartItems')) || {};
    setAddedToCart(savedCart);
  }, []);

  return (
    <>
      <li key={room.id} className="list-group-item p-3">
        <div className="d-flex row">
          <div className="col-3 p-5">
            <img
              src="/images/large.png"
              className="img-fluid rounded"
              alt="indoor"
            />
          </div>
          <div className="col-9 my-auto">
            <div className="row">
              <div className="d-flex flex-column gap-3 col-8 ">
                <p className="mb-0 a-font" style={{fontSize:'32px' , fontWeight:700 , color:'#202224'}}>
                  {room.name} | {room.size_field}
                </p>
                <p className="mb-0 a-font" style={{fontSize:'16px' , fontWeight:500 , color:'#202224'}}>
                   <IoCheckmark size={18}></IoCheckmark> {room.storage_unit_type}
                </p>
                <p className="mb-0 a-font" style={{fontSize:'14px' , fontWeight:500 , color:'#202224'}}>
                  <FaCircle size={7}  style={{ marginRight: '13px' }}></FaCircle>
                  {room.description}
                </p>
              </div>
              {user ? (
                <>
                  <div className="d-flex flex-column col-4 gap-3 text-center">
                    <div>
                    <p className="a-font mb-0">
                      <span className="a-font" style={{fontSize:'32px' , fontWeight:800 , color:'#202224'}}>
                        $ {room.per_unit_price}
                      </span>
                      <span className="text-secondary" style={{fontSize:'12px' , fontWeight:400 , color:'#202224'}}> / month</span>
                    </p>
                    </div>
                    <div>
                    <button
                      type="button" data-bs-toggle="modal" data-bs-target="#staticBackdrop"
                      onClick={() => { handleShowModal() ; addToCart(room.id) ;}}
                      style={{
                        backgroundColor: addedToCart[room.id] ? '#6c757d' : room.free_space !== 0 ? '#ffcc00' : '#6c757d',
                        border: 'none',
                        borderRadius: '4.5px',
                        color: '#000034',
                      }}
                      className={`a-font border-0 p-2 px-3 text-dark`}
                      disabled={room.free_space === 0 || addedToCart[room.id]}
                    >
                      {room.free_space === 0 ? (
                        "Reserved"
                      ) : (
                        <>
                        {addedToCart[room.id] ? (
                            "Added to Cart"
                          ) : (
                            <span>
                                <BsCartPlus size={20} className=""/> Reserve Now
                              </span>
                          )}
                      </>  
                      )}
                    </button>
                    </div>
                    <div>
                    {room.storage_promotions.map((promo, index) => (
                  <OverlayTrigger
                    key={index}
                    overlay={
                      <Tooltip>
                        If you buy for {promo.total_months} months or more, you
                        will get {promo.value}
                        {promo.type === "percentage_off"
                          ? "% off for"
                          : promo.type === "dollar_off"
                            ? "$ off for"
                            : "$ for first"}{" "}
                        {promo.offer_months} months
                      </Tooltip>
                    }
                    placement="top"
                  >
                    <span
                      className="badge bg-danger m-1"
                      style={{ cursor: "default" }}
                    >
                      {promo.value}
                      {promo.type === "percentage_off"
                        ? "% off"
                        : promo.type === "dollar_off"
                          ? "$ off"
                          : `$ for first ${promo.offer_months} months`}
                    </span>
                  </OverlayTrigger>
                ))}
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <div className="col-4 text-center">
                    Login / Sign up to reserve.
                  </div>
                </>
              )}
              
            </div>
          </div>
        </div>
      </li>
    

    {/* //modal */}
    <div className="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
      <div className="modal-dialog"
      >
        <div className="modal-content" style={{backgroundColor:'#ffcc00' , padding:'38px'}}>
          <div className="modal-body">
            <div
                className="d-flex align-items-center justify-content-center mx-auto"
                style={{
                  width: '79px',
                  height: '79px',
                  borderRadius: '50%',
                  backgroundColor: '#FFFFFF',
                  fontWeight: 'bold',
                  margin: '0 5px',
                  color: '#000',
                  boxShadow:'0px 0px 0px 20px #FFFFFF40',
                }}
              >
                <IoMdCheckmark size={45} color="#000034"></IoMdCheckmark>
                </div>
                <div className="d-flex flex-column gap-2 text-center align-items-center mt-5">
                  <p className="a-font mb-0" style={{fontSize:'26px' , fontWeight:700 , color:'#39353C' }}>Your Order is added to the cart.</p>
                  <p className="a-font mb-0" style={{fontSize:'17px' , fontWeight:400 , color:'#39353C' }}>Please check your cart to complete the reservation process.</p>
                </div>

                <div className="d-flex gap-3 mt-5">
                  <button className="d-flex gap-2 p-2 px-3 a-font align-items-center "
                   style={{backgroundColor:'#000034',
                      fontSize:'19px', 
                      fontWeight:600,
                      color:'#FFFFFF',
                      border:'none',
                      borderRadius:'10px'
                  }}
                  data-bs-dismiss="modal"
                  onClick={() => navigate(`/cart`)}

                  >
                      <MdOutlineShoppingCartCheckout size={20} marginRight={3}></MdOutlineShoppingCartCheckout> 
                      <p className="mb-0 a-font"
                      style={{
                        fontSize:'19px', 
                        fontWeight:600,
                    }}
                      >Check Out</p>
                    </button>

                <button className="p-2 px-3 a-font"
                   style={{backgroundColor:'#FFFFFF',
                      fontSize:'19px', 
                      fontWeight:600,
                      color:'#202224',
                      border:'none',
                      borderRadius:'10px',
                  }}
                  data-bs-dismiss="modal"
                  >
                    Continue Shopping
                    </button>

                </div>
          </div>
          {/* <div className="modal-footer">
            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
            <button type="button" className="btn btn-primary">Understood</button>
          </div> */}
        </div>
      </div>
    </div>
    </>
  );
});

export default IndoorStorageList;
