import React, { useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import {
  getOrderDetailsAdmin,
  getOrderDetails,
} from "../../../actions/ordersActions";
import Spinner from "../../../shared-components/Spinner/Spinner";
import { UPDATE_ORDERS_ADMIN_RESET } from "../../../constants/ordersConstants";
import { toast } from "react-toastify";
import OrderDetailsServicePovider from "./OrderDetailsServicePovider";
import OrderDetailsUser from "./OrderDetailsUser";

const OrdersDetails = () => {
  const { id } = useParams();
  const { info } = useSelector((state) => state.userInfo);
  const { loading, order, error } = useSelector(
    (state) => state.orderDetailsAdmin,
  );
  const {
    loading: loading_user,
    order: order_user,
    error: error_user,
  } = useSelector((state) => state.orderDetails);
  const isServiceProvider = info && info.is_service_provider;
  const loadingSelector = isServiceProvider ? loading : loading_user;
  const { loading: updateLoading, order: updateOrder } = useSelector(
    (state) => state.updateOrderAdmin,
  );

  const dispatch = useDispatch();

  const navigate = useNavigate();

  useEffect(() => {
    if (info && info.is_service_provider) {
      dispatch(getOrderDetailsAdmin(id));
      if (updateOrder) {
        navigate("/service-provider-dashboard/analytics");
        toast.success("Updated");
        dispatch({ type: UPDATE_ORDERS_ADMIN_RESET });
      }
    } else {
      dispatch(getOrderDetails(id));
    }
  }, [dispatch, updateOrder]);

  // console.log("order details " , order_user )
  return (
    <div
      className="py-5"
      style={{
        background:
          info && !info.is_service_provider ? "#f2edf3" : "transparent", // Set a default value if the condition is false
      }}
    >
      {loadingSelector || updateLoading ? (
        <Spinner />
      ) : (
        <>
          {/* <div className="d-flex justify-content-center p-2 m-2">
          <p className="mb-0 a-font" 
            style={{fontSize:'36px' , fontWeight:700 , color:'#202224'}}>
              Order <span className="mineTextOrange"> Details</span>
            </p>
          </div> */}
          <div
            className={`container ${
              !info.isServiceProvider ? "service-card mt-5 p-4" : ""
            }`}
          >
            {!info.is_service_provider ? (
              <div className={``}>
                <div className="">
                  <div className="">
                    {!true ? (
                      ""
                    ) : (
                      <>
                      <p className="mb-0 a-font" 
                      style={{fontSize:'36px' , fontWeight:700 , color:'#202224'}}>
                        Unit Reservation Detail
                      </p>
                        <hr></hr>
                        </>
                    )}
                      
                        <div className="d-flex gap-3 p-4">
                        
                        { order_user.order.length > 0 ? (<></>):
                          (<div className="d-flex col-6 justify-content-center" >
                            <div id="imageCarousel" className="carousel slide" data-bs-ride="carousel" style={{
                              width:'500px',
                              height:'300px',
                            }} >
                              <div className="carousel-indicators">
                                {(["/images/storage1.jpg", "/images/storage2.jpg", "/images/storage3.jpg"]).map((_, index) => (
                                  <button
                                    type="button"
                                    key={index}
                                    data-bs-target="#imageCarousel"
                                    data-bs-slide-to={index}
                                    className={index === 0 ? "active" : ""}
                                    aria-current={index === 0 ? "true" : ""}
                                    aria-label={`Slide ${index + 1}`}
                                  ></button>
                                ))}
                              </div>

                              <div className="carousel-inner" style={{height:'300px'}}>
                              {(["/images/storage1.jpg","/images/storage2.jpg","/images/storage3.jpg"]).map((item, index) => (
                                  <div
                                    key={index}
                                    className={`carousel-item ${index === 0 ? "active" : ""}`}
                                  >
                                    <img
                                    style={{
                                      width:'500px',
                                      height:'300px',
                                      objectFit:'cover',
                                      borderRadius:'20px'
                                    }}
                                      src={item.image || item}
                                      className="d-block w-100"
                                      alt={`Slide ${index + 1}`}
                                    />
                                  </div>
                                ))}
                              </div>

                              <button
                                className="carousel-control-prev"
                                type="button"
                                data-bs-target="#imageCarousel"
                                data-bs-slide="prev"
                              >
                                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                <span className="visually-hidden">Previous</span>
                              </button>
                              <button
                                className="carousel-control-next"
                                type="button"
                                data-bs-target="#imageCarousel"
                                data-bs-slide="next"
                              >
                                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                <span className="visually-hidden">Next</span>
                              </button>
                            </div>
                          </div>)
                        }
                            
                            <div className="col-6 d-flex flex-column gap-3 my-auto">
                            
                                <p className="mb-0 a-font" 
                                      style={{fontSize:'24px' , fontWeight:700 , color:'#202224'}}>
                                        Order Details:</p>
                                    <div>
                                    <p className="mb-0 a-font" 
                                  style={{fontSize:'16px' , fontWeight:400 , color:'#202224'}}>
                                    Order ID: {order_user.slug}</p>
                                    <p className="mb-0 a-font" 
                                  style={{fontSize:'16px' , fontWeight:400 , color:'#202224'}}>
                                    Business Type: {order_user.business_type}</p>
                                    <p className="mb-0 a-font" 
                                        style={{ fontSize: '16px', fontWeight: 400, color: '#202224' }}>
                                        Created At: {new Date(order_user.created_at).toLocaleDateString('en-US', {
                                            month: 'long',
                                            day: 'numeric',
                                            year: 'numeric'
                                        })}
                                    </p>
                                    </div>

                              <p className="mb-0 a-font" 
                                  style={{fontSize:'24px' , fontWeight:700 , color:'#202224'}}>
                                    Total Amount:</p>

                                <div>
                                    <p className="mb-0 a-font" 
                                  style={{fontSize:'16px' , fontWeight:400 , color:'#202224'}}>
                                    Price: ${order_user.total_price}</p>
                                    <p className="mb-0 d-flex a-font gap-1" 
                                  style={{fontSize:'16px' , fontWeight:400 , color:'#202224'}}>
                                    Payment Status: {order_user.payment_completed===false ? (<p>Incomplete</p>) : (<p>Completed</p>)}</p>
                                    </div>

                            </div>

                        </div>
                  </div>
                </div>
              </div>
            ) : (
              <div className="d-flex ">
                <p className="fw-bold ">
                  Business Type:{" "}
                  <span className=" text-dark ">
                    {order.order.business_type.toUpperCase()}
                  </span>
                </p>
              </div>
            )}
            <div className="row">
              <div className=" col-12 ">
                {info && info.is_service_provider ? (
                  <OrderDetailsServicePovider order={order} />
                ) : (
                  <div>
                    <OrderDetailsUser order={order_user} id={id} />
                  </div>
                )}
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default OrdersDetails;
