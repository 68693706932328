import React ,{useState} from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { FaMapMarkerAlt, FaCalendarAlt, FaLock } from 'react-icons/fa';
import { FaSearch } from "react-icons/fa";
import { useNavigate,createSearchParams } from 'react-router-dom';

function StorageJourney() {
    const navigate=useNavigate();

    /////////To find storage ; BY deafult in button prop location is new york
  const [formDatabutton, setFormDatabutton] = useState({
    location: "",
    storage_type: [],
    small_size: 0,
    medium_size: 0,
    large_size: 0,
  });

  const ButtonState = (location) => {
    setFormDatabutton((prev) => {
      const updatedData = { ...prev, location };
  
      // console.log("~~Updated form data:", updatedData);
      localStorage.setItem("us_Storage_facility", JSON.stringify(updatedData));
  
      navigate({
        pathname: "/storages/results",
        search: `?${createSearchParams(updatedData)}`,
      });
  
      return updatedData;
    });
  };


  return (
    <div className="text-center">
    <div className="row justify-content-center">
        <div className="col-12 col-sm-10 col-md-8 col-lg-6 sj-heading">
            <p style={{color:'#ffcc00'}}>
                Search<span style={{ color: "#000034" }}> it. </span>
                Select<span style={{ color: "#000034" }}> it. </span>
                Store <span style={{ color: "#000034" }}> it. </span>
                {/* Your <span style={{ color: '#FFCC00' }}>Self-Storage</span> Journey, Simplified. */}
            </p>
        </div>

        <div className="my-5">
        <Row className="justify-content-center align-items-center mt-4">

            <Col md={2} className="d-flex flex-column align-items-center">
            <div className="circle">
                <img src='/images/location.svg' alt='location' width="60" height="60" />
            </div>
            <h5 className="mt-2 sj-mainh text-center mt-4">STEP 1</h5>
            <h4 className="sj-interp text-center">Choose Your Location</h4>
            <p className="sj-desc text-center">Quick and convenient.</p>
            </Col>

            <Col md={2} className="d-flex justify-content-center align-items-center custom-separator-margin">
            <div className="separator"></div>
            </Col>



            <Col md={2} className="d-flex flex-column align-items-center">
            <div className="circle">
                <img src='/images/reserve.svg' alt='reserve' width="60" height="60" />
            </div>
            <h5 className="mt-2 sj-mainh text-center mt-4">STEP 2</h5>
            <h4 className="sj-interp text-center">Reserve Your Space</h4>
            <p className="sj-desc text-center">No commitments.</p>
            </Col>


            <Col md={2} className="d-flex justify-content-center align-items-center custom-separator-margin">
            <div className="separator"></div>
            </Col>

            <Col md={2} className="d-flex flex-column align-items-center">
            <div className="circle">
                <img src='/images/easyaccess.svg' alt='easyaccess' width="60" height="60" />
            </div>
            <h5 className="mt-2 sj-mainh text-center mt-4">STEP 3</h5>
            <h4 className="sj-interp text-center">Store with Ease</h4>
            <p className="sj-desc text-center">Access anytime.</p>
            </Col>
        </Row>
        </div>



        <div className="">
            <button
                className="m-3"
                style={{
                    fontWeight: '700',
                    fontSize: '24px',
                    backgroundColor: '#ffcc00',
                    border: 'none',
                    borderRadius: '10px',
                    color: '#000034',
                    paddingTop: '6px',   
                    paddingBottom: '6px',
                    paddingLeft: '28px', 
                    paddingRight: '28px', 
                }}
                onClick={() => ButtonState("New York")}

            >
                Find Storage
                <FaSearch size={20} style={{ marginLeft: '8px' }} />
            </button>
        </div>
    </div>
    </div>
  );
}

export default StorageJourney;
