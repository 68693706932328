import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

import CartList from "./CartList";
import { cartGetItems } from "../../actions/cartActions";
import CheckoutCard from "./CheckoutCard";
import Spinner from "../../shared-components/Spinner/Spinner";

import "./cart.css";

const Cart = () => {
  const dispatch = useDispatch();
  const [selectedStartingDates, setSelectedStartingDates] = useState([]);
  const [blockCheckoutArray, setBlockCheckoutArray] = useState([]);
  const [blockCheckout, setblockCheckout] = useState();
  const [totalPrice, setTotalPrice] = useState(0);
  const [finalPrice, setFinalPrice] = useState(0);
  const [discountPrice, setDiscountPrice] = useState(0);
  const [platformFees, setPlatformFees] = useState(0);
  const [stripeFees, setStripeFees] = useState(0);

  const [unselected, setUnselected] = useState([]);
  const { loading, error, cartItems } = useSelector(
    (state) => state.cartGetItems,
  );

  const [backgroundBlur, setBackgroundBlur] = useState(false);

  useEffect(() => {
    if (cartItems) {
      const cartItemsLength = cartItems[0].storage_unit.length;
      setBlockCheckoutArray(
        Array.from({ length: cartItemsLength }, () => ({
          blockCheckout: false,
        })),
      );
    
      const selectedIds = cartItems[0].storage_unit.map(itemid => itemid.id);
      console.log("zelected " , selectedIds);

      const filteredArray = selectedStartingDates.filter(date => 
        selectedIds.includes(date.storage_unit_ids)
      );

      console.log("filtered array is " , filteredArray);

      setSelectedStartingDates(filteredArray);    
    }
  }, [cartItems]);

  console.log("cart item "  , cartItems);

  useEffect(() => {
    if (blockCheckoutArray) {
      const areAllFalse = blockCheckoutArray.reduce(
        (accumulator, currentValue) => {
          return accumulator || currentValue.blockCheckout;
        },
        false,
      );

      setblockCheckout(areAllFalse);
    }
  }, [blockCheckoutArray]);

  useEffect(() => {
    let totalPrice = selectedStartingDates.reduce(
      (accumulator, currentValue) => {
        return accumulator + (parseFloat(currentValue.total_price) || 0.0);
      },
      0.0,
    );
    let finalPrice = selectedStartingDates.reduce(
      (accumulator, currentValue) => {
        return accumulator + (parseFloat(currentValue.final_price) || 0.0);
      },
      0.0,
    );
    
    let platformFees = finalPrice === 0 ? 0 : (finalPrice * 0.05);
    platformFees = platformFees < 5 ? 5 : platformFees;

    let stripeFees = 0;

    totalPrice = totalPrice.toFixed(2);
    finalPrice = finalPrice.toFixed(2);
    platformFees = platformFees.toFixed(2);
    stripeFees = stripeFees.toFixed(2);

    let discountPrice = totalPrice - finalPrice;
    discountPrice = discountPrice.toFixed(2);

    setTotalPrice(totalPrice);
    setFinalPrice(finalPrice);
    setDiscountPrice(discountPrice);
    setPlatformFees(platformFees);
    setStripeFees(stripeFees);

  }, [selectedStartingDates]);

  const handleDataFromChild = (data) => {
    if (!data || Object.keys(data).length === 0) return; // Ignore empty data
  
    setSelectedStartingDates((prev) => {
      const existingIndex = prev.findIndex(
        (item) => item.storage_unit_ids === data.storage_unit_ids
      );
  
      if (existingIndex === -1) {
        return [...prev, data]; // Add new item if not found
      } else {
        const updatedDates = [...prev];
        updatedDates[existingIndex] = data; // Update existing item
        return updatedDates;
      }
    });
  };
  
  


  useEffect(() => {
    dispatch(cartGetItems());
  }, [dispatch]);

  const handleUnselected = (ids) => {
    // Filter out undefined values from the ids array
    const filteredIds = ids.filter((id) => id !== undefined);
    setUnselected(filteredIds);
  };

  return (
    <div className="w-100" style={{backgroundColor:'#F5F6FA'}}>
    <div className="container py-3">
      <p className="a-font" style={{fontSize:'42px' , fontWeight:700 , color:'#202224'}}>Confirm Your Units</p>

      <div className="row mt-3">
        <div
          className={`col-md-9 service-card p-4 ${backgroundBlur && "checkout_backdrop_filter"} `}
        >
          {loading ? (
            <Spinner />
          ) : error ? (
            <>
              <div className="alert alert-info">No Items in the Cart.</div>
            </>
          ) : (
            <>
              <p className="a-font" style={{fontSize:'26px' , fontWeight:600 , color:'#202224'}}>Storage Unit Items</p>
              <ul className="list-group list-group-flush">
                {cartItems && cartItems[0].storage_unit.length === 0 ? (
                  <>
                    <div className="alert alert-info">Your cart is Empty!</div>
                  </>
                ) : (
                  <>
                    {cartItems[0].storage_unit.map((item, index) => (
                      <CartList
                        key={item.id}
                        arrIndex={index}
                        item={item}
                        handleDataFromChild={handleDataFromChild}
                        unselected={unselected}
                        blockCheckoutArray={blockCheckoutArray}
                        setBlockCheckoutArray={setBlockCheckoutArray}
                      />
                    ))}
                    <li className="list-group-item">
                      <div className="d-flex justify-content-between align-items-center">
                        <div>
                        <p className="a-font mb-0" style={{fontSize:'24px' , fontWeight:600 , color:'#202224'}}>Sub Total: </p>
                        </div>
                        <div>
                        <p className="a-font mb-0" style={{fontSize:'34px' , fontWeight:600 , color:'#202224'}}>${finalPrice}</p>
                        </div>
                      </div>
                    </li>
                  </>
                )}
              </ul>
            </>
          )}
        </div>
        <div tabIndex="99" className="col-md-3">
          <CheckoutCard
            subTotal={totalPrice}
            totalPrice={totalPrice}
            finalPrice={finalPrice}
            discountPrice={discountPrice}
            platformFees={platformFees}
            stripeFees={stripeFees}
            id={cartItems && cartItems}
            dateArray={selectedStartingDates}
            handleUnselected={handleUnselected}
            blockCheckout={blockCheckout}
            setBackgroundBlur={setBackgroundBlur}
          />
        </div>
      </div>
    </div>
    </div>
  );
};

export default Cart;
