import React, { useState , useEffect } from "react";
import { FaStore, FaBusinessTime } from "react-icons/fa";
import { MdOutlineEmojiTransportation } from "react-icons/md";

import BusinessStorage from "./BusinessUnitStorage/BusinessUnitStorage";
import UnitStorageForm from "./UnitStorageForm/UnitStorageForm";
import ValueAddedServices from "./ValueAddedServices/ValueAddedServices";
import Select from "react-select";
import GeoApiAuto from "../../GeoApiAuto/GeoApiAuto";
import GeoApiAutoButtonSearch from "../../GeoApiAuto/GeoApiAutoButtonSearch";

import { Link, createSearchParams, useNavigate } from "react-router-dom";

import { toast } from "react-toastify";
import { LuLoader } from "react-icons/lu";

const HomeCard = () => {
  const [isLocationSet, setIsLocationSet] = useState(false);
  const [isLoading, setIsLoading] = useState(false); 
  const [formData, setFormData] = useState({
    location: "",
    storage_type: [],
    small_size: 0,
    medium_size: 0,
    large_size: 0,
  });

  const getLocationData = (data, id) => {
    // console.log("~~~ data is " , data);
    if (!data) {
      setIsLocationSet(false);
      return setFormData((prev) => {
        return {
          ...prev,
          [id]: "",
        };
      });
    }

    let locationData = {
      address: data.properties.formatted || "",
      city: data.properties.city || "",
      country: data.properties.country || "",
      country_code: data.properties.country_code || "",
      county: data.properties.county || "",
      postcode: data.properties.postcode || 0,
      district: data.properties.district || null,
      lat: data.properties.lat,
      lon: data.properties.lon,
    };

    // setFormData((prev) => {
    //   console.log('set form data ' , locationData.address );
    //   return {
    //     ...prev,
    //     [id]: locationData.address,
    //   };
    // });

    setFormData((prev) => {
    const updatedFormData = { ...prev, [id]: locationData.address };
    if (id === "location") setIsLocationSet(true); // Mark when the location is ready
    return updatedFormData;
  });
    setIsLocationSet(true);
  };

  const navigate = useNavigate();

  const submitFormHandler = async (event) => {
    setIsLoading(true);
    event.preventDefault();
    // console.log("~~~ submit form data " , formData);
    await waitUntilLocationIsSet();
    // console.log("~~~ submitinggggggg " , formData);

    // await new Promise((resolve) => setTimeout(resolve, 1000)); 

    if (!formData.location) {
      return toast.error("Location is Required.");
    }

    setIsLoading(false);
    localStorage.setItem("us_Storage_facility", JSON.stringify(formData));

    navigate({
      pathname: "/storages/results",
      search: `?${createSearchParams(formData)}`,
    });
  };

  const [formDatabutton, setFormDatabutton] = useState({
    location: "",
    storage_type: [],
    small_size: 0,
    medium_size: 0,
    large_size: 0,
  });

  const ButtonState = (location) => {

    setFormDatabutton((prev) => {
      const updatedData = { ...prev, location };
  
      // console.log("~~~pdated form data:", updatedData);
      localStorage.setItem("us_Storage_facility", JSON.stringify(updatedData));
  
      navigate({
        pathname: "/storages/results",
        search: `?${createSearchParams(updatedData)}`,
      });
  
      return updatedData;
    });
  };
  
  const waitUntilLocationIsSet = async () => {
    return new Promise((resolve) => {
      const interval = setInterval(() => {
        if (isLocationSet) {
          clearInterval(interval); // Stop polling
          resolve();
        }
      }, 100); // Check every 100ms
    });
  };

  
  const [selectedImage, setSelectedImage] = useState('/images/mainlandingpic.jpg'); 
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isRotating, setIsRotating] = useState(true); 

  const imageList = [
    { src: '/images/mainlandingpic.jpg', alt: 'Main Landing' },
    { src: '/images/FxS_Photo_1.jpeg', alt: 'Image 1' },
    { src: '/images/FxS_Photo_2.jpeg', alt: 'Image 2' },
    // { src: '/images/FxS_Photo_3.jpeg', alt: 'Image 3' },
    { src: '/images/FxS_Photo_4.jpeg', alt: 'Image 4' },
    // { src: '/images/FxS_Photo_5.jpeg', alt: 'Image 5' },
    // { src: '/images/FxS_Photo_6.jpeg', alt: 'Image 6' },
    { src: '/images/FxS_Photo_7.jpeg', alt: 'Image 7' },
    { src: '/images/FxS_Photo_8.jpeg', alt: 'Image 8' },
    { src: '/images/FxS_Photo_9.jpeg', alt: 'Image 9' },
    { src: '/images/FxS_Photo_10.jpeg', alt: 'Image 10' },
    { src: '/images/FxS_Photo_11.jpeg', alt: 'Image 11' },
  ];

  const changeImage = (index) => {
    setSelectedImage(imageList[index].src);
    setCurrentIndex(index);
  };

  useEffect(() => {
    if (isRotating) {
      const timer = setInterval(() => {
        setCurrentIndex((prevIndex) => {
          const nextIndex = (prevIndex + 1) % imageList.length;
          changeImage(nextIndex);
          return nextIndex;
        });
      }, 3000); 

      return () => clearInterval(timer);
    }
  }, [isRotating]);

  const handleImageClick = (index) => {
    setIsRotating(false);
    changeImage(index);
  };

  return (
    <>
<div className="container-fluid outer-container d-flex flex-column flex-md-row justify-content-between mt-4">

<div className="flex-fill w-100 w-md-50" style={{marginBottom:'80px'}}>
<div className="col d-flex flex-column gap-3 mt-4">
      <p className="sj-heading-main text-start a-font" 
      style={{fontWeight:700,
        lineHeight:0.9
      }}>
        Get Connected with storage spaces in minutes
        </p>
      <p className="a-font col-md-8" style={{ color: '#000034', fontWeight: '500', fontSize: '30px' ,letterSpacing:'-0.5px',lineHeight:1 }}>
      Thousands of spaces near you within a single platform
        </p>
      <p className="a-font"
       style={{ color: '#000034', fontWeight: '600', fontSize: '24px',letterSpacing:'-1px', 
        marginTop:'60px',
       }}>
        Search from largest storage marketplace in USA</p>
      <div style={{ width: '130%', marginLeft: '0.5%', zIndex: 20 }}>
        <div className="form-group w-100">
          <div className="d-flex mb-4" style={{
            backgroundColor: 'white',
            borderRadius: '25px',
          }}>
            <div
              style={{
                width: 'auto',
                paddingTop: '30px',
                paddingBottom: '25px',
                paddingLeft:'20px',
                justifyContent: "center",
                borderRadius: '25px',
                position: "absolute",
                zIndex: 30,
              }}
            >
              <div style={{ width: '100%' }}>
                <GeoApiAuto id="location" getLocationData={getLocationData} />
              </div>
            </div>
            <div className="d-flex flex-row ddflex"
                  style={{ justifyContent: 'flex-end' }}
                >
              <Link to="/storages/results">
                <button                
                  type="button"
                  onClick={submitFormHandler}
                  disabled={!isLocationSet}
                  className=""
                  style={{
                    fontWeight: '600',
                    fontSize: '20px',
                    backgroundColor: '#ffcc00',
                    border: 'none',
                    borderTopRightRadius:'10px',
                    borderBottomRightRadius:'10px',
                    color: '#000034',
                    paddingTop: '30px',
                    paddingBottom: '31px',
                    paddingLeft: '20px',
                    paddingRight: '20px',
                    zIndex: 30,
                    position: 'relative',
                  }}
                >
                  {isLoading ? <>Finding <LuLoader /></>: "Find Storage"}
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>
      <p className="a-font d-flex" style={{ color: '#000034', fontWeight: '400', fontSize: '20px' , gap:'2px' }}>
        <strong>Popular: </strong>
        <span
      className="text-decoration-underline"
      style={{ cursor: "pointer"}}
      onClick={() => ButtonState("Chicago")}
    >
      Chicago
    </span>
      , 
      <span
        className="text-decoration-underline"
        style={{ cursor: "pointer"}}
        onClick={() => ButtonState("Texas")}
      >
        Texas
      </span>
      , 
      <span
        className="text-decoration-underline"
        style={{ cursor: "pointer"}}
        onClick={() => ButtonState("New York")}
      >
        New York
      </span>
      , 
      <span
        className="text-decoration-underline"
        style={{ cursor: "pointer"}}
        onClick={() => ButtonState("Dallas")}
      >
        Dallas
      </span>
        {/* <button
         className=""
        style={{backgroundColor:'#000034',
          color:"white",
          border:'none',
          borderRadius:'15px',
          fontSize:'16px',
          alignItems:"center",
          alignContent:"center",
          paddingLeft:'16px',
          paddingRight:'16px',
          marginLeft:'20px',
        }}
        onClick={() => {
          navigate('/storages/results?location=New+York%2C+United+States+of+America&small_size=0&medium_size=0&large_size=0')
        }}
        >Near Me</button> */}
      </p>
    </div>
  </div>

  <div className="flex-fill d-none d-md-flex justify-content-center align-items-center" style={{marginTop:'80px'}} >
  <div className="p-3 pl-4 pb-4 inner-div rounded-lg" 
  style={{ width: '80%', height: '100%', backgroundColor: '#FFDF61', borderTopLeftRadius: '25px', borderTopRightRadius: '25px', 
   position: 'relative',
   }}>
    <div className="picture-div rounded-lg"
    style={{ width: '110%', height: '120%', borderRadius: '25px' ,marginTop:'-20%' }}>
      <img
        src={selectedImage}
        alt="Selected Image"
        style={{
          objectFit: 'cover',
          position: 'absolute',
          top: 0,
          left: '50%',
          transform: 'translateX(-50%)',
          width: '175%',
          height: '135%',
          borderRadius: '10px',
          zIndex: 0,
        }}
      />
    </div>
  </div>
</div>


</div>

    </>
  );
};

export default HomeCard;
