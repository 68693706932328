import axios from "axios";
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { Spinner } from "react-bootstrap";
import Container from "../../shared-components/container/Container";
import { updateUserEmail, updateUserPassword } from "../../actions/userActions";
import {
  USER_EMAIL_UPDATE_RESET,
  USER_PASSWORD_UPDATE_RESET,
} from "../../constants/userConstants";
import activateStripe from "../../hooks/activateStripe";
import ServiceProviderDetailsUpdate from "../auth/serviceProviderDetails/ServiceProviderDetailsUpdate";
import { IoCheckmark } from "react-icons/io5";
import { RxCross1 } from "react-icons/rx";

const MyProfile = () => {
  const { info } = useSelector((state) => state.userInfo);
  const { user } = useSelector((state) => state.userLogin);
  const { message, error } = useSelector((state) => state.userEmailUpdate);
  const { message: passwordMessage, error: passwordError } = useSelector(
    (state) => state.userPasswordUpdate,
  );
  const { serviceProvider } = useSelector((state) => state.getServiceProvider);

  const [stripeLoading, setStripeLoading] = useState(false);
  const [activeSection, setActiveSection] = useState("personal"); // Default to 'personal'

  const [email, setEmail] = useState(info?.email || "");
  const [passwordData, setPasswordData] = useState({
    old_password: "",
    password: "",
    password2: "",
  });

  const dispatch = useDispatch();

  // Email Update Handler
  const emailUpdateHandler = (e) => {
    setEmail(e.target.value);
  };

  // Password Update Handler
  const passwordUpdateHandler = (e) => {
    setPasswordData((prev) => ({
      ...prev,
      [e.target.id]: e.target.value,
    }));
  };

  // Email Update Submit Handler
  const emailUpdateSubmitHandler = (e) => {
    e.preventDefault();
    dispatch(updateUserEmail({ email }));
  };

  // Password Update Submit Handler
  const passwordUpdateSubmitHandler = (e) => {
    e.preventDefault();

    if (
      !passwordData.old_password ||
      !passwordData.password ||
      !passwordData.password2
    ) {
      return toast.error("Password fields must not be empty!");
    }

    if (/^\d+$/.test(passwordData.password)) {
      return toast.error("New password must not be all numeric.");
    }

    if (passwordData.password !== passwordData.password2) {
      return toast.error("Passwords do not match!");
    }

    dispatch(updateUserPassword(passwordData));
  };

  // Side Effects
  useEffect(() => {
    if (message) {
      toast.success(`Updated! ${message}`);
      dispatch({ type: USER_EMAIL_UPDATE_RESET });
    }

    if (error) {
      toast.error(error);
      dispatch({ type: USER_EMAIL_UPDATE_RESET });
    }

    if (passwordMessage) {
      toast.success(passwordMessage);
      dispatch({ type: USER_PASSWORD_UPDATE_RESET });
    }
    if (passwordError) {
      toast.error(passwordError);
      dispatch({ type: USER_PASSWORD_UPDATE_RESET });
    }
  }, [message, error, passwordMessage, passwordError]);

  async function handleActiveStripe() {
    setStripeLoading(true);
    const status = await activateStripe(user);
    setStripeLoading(status);
  }
  const resetForm = () => {
    setEmail(info?.email || ""); // Reset email to the initial value
    setPasswordData({
      old_password: "",
      password: "",
      password2: "",
    }); // Reset password fields to empty
  };
  
  // Handlers for section buttons
  const showPersonal = () => setActiveSection("personal");
  const showSecurity = () => setActiveSection("security");
  const showProviderSettings = () => setActiveSection("providerSettings");

  console.log("settings check  " , serviceProvider)
  return (
    <Container>
      <div>
        <p className="a-font mb-0" style={{fontSize:'32px' , fontWeight:700}}>Settings</p>
          <div className="service-card p-4"
          style={{border:'0.3px solid #B9B9B9',
            borderRadius:'14px',
          }}>
          <div className="d-flex flex-column col-12">
            <div className="d-flex gap-5" style={{backgroundColor:'#FCFDFD'}}>
            <button className="a-font mb-0 text-uppercase"
            style={{border:'none' , backgroundColor:'transparent' , fontSize:'14px' , fontWeight:600 , 
              color : activeSection==="personal" ? '#ffcc00' : '#000034' 
              }} 
            onClick={showPersonal}
            >Account</button>
            <button className="a-font mb-0 text-uppercase" 
            style={{border:'none' , backgroundColor:'transparent' , fontSize:'14px' , fontWeight:600 , 
              color : activeSection==="security" ? '#ffcc00' : '#000034' 
            }} 
            onClick={showSecurity}
            >Security</button>
            {serviceProvider ? (
              <button className="a-font mb-0 text-uppercase" 
              style={{border:'none' , backgroundColor:'transparent' , fontSize:'14px' , fontWeight:600 , 
                color : activeSection==="providerSettings" ? '#ffcc00' : '#000034' 
              }}
              onClick={showProviderSettings}
              >Provider Settings</button>
              ) : (
              <></>
            )}
            </div>
            <hr></hr>
          </div>
          {activeSection === "personal" && (
          <div>
          <div>
            <p className="a-font mb-0" style={{fontSize:'22px' , fontWeight:600 , color:'#202224'}}>Your Profile</p>
            <p className="n-font mb-0" style={{fontSize:'14px' , fontWeight:500 , color:'#202224'}}>
            Please update your profile settings here
            </p>
            </div>
            
            <hr></hr>
            <div className="d-flex" style={{gap:'140px'}}>
            <p className="a-font mb-0" style={{fontSize:'22px' , fontWeight:600 , color:'#202224'}}>Email</p>
                <form onSubmit={emailUpdateSubmitHandler}>
                  <div className="form-group mb-3">
                    <input
                      type="email"
                      name="email"
                      id="email"
                      onChange={emailUpdateHandler}
                      className="px-3 p-1 n-font"
                      style={{backgroundColor:'#F1F4F9' ,border:'1px solid #D8D8D8' , borderRadius:'8px' , fontSize:'14px',
                        fontSize:'14px' , fontWeight:500 , color:'black'
                      }}
                      placeholder="email"
                      value={email}
                    />
                  </div>
                  {/* <button className="btn btn-primary text-dark">
                    Update Email
                  </button> */}
                  <button className="p-1 px-3 d-flex gap-2 align-items-center"
                        style={{
                          backgroundColor: '#FFCC00',
                          borderRadius: '6px',
                          border: 'none',
                        }}>
                        <IoCheckmark size={12} />
                    Update Email
                  </button>
                  {user?.service_provider && user?.is_provider_verified && (
                    <div className="mt-2">
                    </div>
                  )}
                </form>
                {user &&
                  !user.is_stripe_account_active &&
                  user.service_provider && (
                    <div className="my-3">
                      <button
                        className="btn btn-primary text-dark"
                        onClick={handleActiveStripe}
                      >
                        Activate Stripe Account
                        {stripeLoading && (
                          <Spinner animation="border" variant="light" size="sm" />
                        )}
                      </button>
                    </div>
                  )}
            </div>
          </div>
          )}
            {activeSection === "security" && (
          <div style={{overflow:'hidden'}}>
          <div>
            <p className="a-font mb-0" style={{fontSize:'22px' , fontWeight:600 , color:'#202224'}}>Password</p>
            <p className="n-font mb-0" style={{fontSize:'14px' , fontWeight:500 , color:'#202224'}}>
            Here you can change the password to your account.
            </p>
            </div>
            <div className="d-flex" style={{gap:'140px'}}>
                <form onSubmit={passwordUpdateSubmitHandler} className="w-100">
                  
                  <div className="d-flex mt-5" style={{gap:'150px'}}>
                    <div className="col-md-6">
                  <p className="a-font mb-0" style={{fontSize:'22px' , fontWeight:600 , color:'#202224'}}>Current Password</p>
                  </div>
                  <div className="col-md-6">
                    <input
                      type="password"
                      name="old_password"
                      id="old_password"
                      onChange={passwordUpdateHandler}
                      value={passwordData.old_password}
                      className="px-3 p-2 n-font"
                      style={{backgroundColor:'#F1F4F9' ,border:'1px solid #D8D8D8' , borderRadius:'8px' , fontSize:'14px',
                        fontSize:'14px' , fontWeight:500 , color:'black'
                      }}
                      placeholder="Old Password"
                    />
                    </div>
                  </div>
                  <hr style={{width: '300%',}}></hr>
                  <div className="d-flex mt-5" style={{gap:'150px'}}>
                  <div className="col-md-6">
                  <p className="a-font mb-0" style={{fontSize:'22px' , fontWeight:600 , color:'#202224'}}>New Password</p>
                  </div>
                  <div className="col-md-6">
                    <input
                      type="password"
                      name="password"
                      id="password"
                      onChange={passwordUpdateHandler}
                      value={passwordData.password}
                      className="px-3 p-2 n-font"
                      style={{backgroundColor:'#F1F4F9' ,border:'1px solid #D8D8D8' , borderRadius:'8px' , fontSize:'14px',
                        fontSize:'14px' , fontWeight:500 , color:'black'
                      }}
                      placeholder="New Password"
                    />
                    </div>
                  </div>
                  <hr style={{width: '300%',}}></hr>
                  <div className="d-flex mt-5" style={{gap:'150px'}}>
                    <div className="col-md-6">
                  <p className="a-font mb-0" style={{fontSize:'22px' , fontWeight:600 , color:'#202224'}}>Confirm New Password</p>
                  </div>
                  <div className="col-md-6">
                    <input
                      type="password"
                      name="password2"
                      id="password2"
                      value={passwordData.password2}
                      onChange={passwordUpdateHandler}
                      className="px-3 p-2 n-font"
                      style={{backgroundColor:'#F1F4F9' ,border:'1px solid #D8D8D8' , borderRadius:'8px' , fontSize:'14px',
                        fontSize:'14px' , fontWeight:500 , color:'black'
                      }}
                      placeholder="Confirm New Password"
                    />
                    </div>
                  </div>
                  <div className="mt-5 gap-4 d-flex justify-content-end">
                  <button
                    type="button"
                    className="p-1 px-3 d-flex gap-2 align-items-center"
                    style={{
                      backgroundColor: '#F1F4F9',
                      borderRadius: '6px',
                      border: 'none',
                      width: '96px',
                    }}
                    onClick={resetForm}
                  >
                    <RxCross1 color="black" size={12} />
                    Cancel
                  </button>


                  <button className="p-1 px-3 d-flex gap-2 align-items-center"
                        style={{
                          backgroundColor: '#FFCC00',
                          borderRadius: '6px',
                          border: 'none',
                        }}>
                        <IoCheckmark size={12} />
                    Update Password
                  </button>
                  </div>
                </form>
            </div>
          </div>
          )}
            {activeSection === "providerSettings" && (
          <div style={{overflow:'hidden'}}>
          <div className="mt-2">
            <p className="a-font mb-0" style={{fontSize:'22px' , fontWeight:600 , color:'#202224'}}>Service Provider Information</p>
            <p className="n-font mb-0" style={{fontSize:'14px' , fontWeight:500 , color:'#202224'}}>
            Please fill it carefully to update your information
            </p>
            <hr></hr>
            </div>
            <div>
                  <ServiceProviderDetailsUpdate />
            </div>
          </div>
          )}
          </div>

      </div>
     

    </Container>
  );
};

export default MyProfile;
