import React, { useState } from "react";
import { BsFillCheckCircleFill } from "react-icons/bs";
import ImageModal from "../../../shared-components/PhotoGallery/PhotoModal";
import { useRef } from "react";
import { LuClock3 } from "react-icons/lu";
import { IoLogIn } from "react-icons/io5";
import { FaLocationDot } from "react-icons/fa6";

const Features = ({ product, storageFacility }) => {
  const allFeatures = storageFacility?.features;
  const [showAllFeatures, setShowAllFeatures] = useState(false);

  const maxFeaturesToShow = showAllFeatures ? allFeatures.length : 5;
  const featuresToShow = allFeatures.slice(0, maxFeaturesToShow);
  const imageModalRef = useRef(null);

  const showImageModal = () => {
    if (imageModalRef.current) {
      imageModalRef.current.showModal();
    }
  };
  console.log("storage facility users " , storageFacility)

  return (
    <div>
      <div className="service-card p-3">
        <li className="list-group-item">
          <p className="a-font mb-0" style={{fontSize:'20px' , fontWeight:700 , color:'#202224'}}>Facility Features</p>
          <div className="mt-2">
            {featuresToShow.slice(1).map((feature, index) => {
              return (
                <p key={index}>
                  <BsFillCheckCircleFill
                    color="#45EAA6"
                    size={20}
                    className="mb-0 a-font"
                    style={{
                      marginRight: "10px",
                    }}
                  />
                  {feature.features_text}
                </p>
              );
            })}
            {allFeatures.length > 5 && !showAllFeatures && (
              <p
                className="text-primary cursor-pointer"
                style={{ cursor: "pointer" }}
                onClick={() => setShowAllFeatures(true)}
              >
                Show More Features
              </p>
            )}
          </div>
        </li>
        <hr></hr>
        <li className="list-group-item">
          <p className="a-font mb-0 py-2" style={{fontSize:'20px' , fontWeight:700 , color:'#202224'}}>Hours</p>
          <div className="d-flex gap-3 align-items-center">
          <LuClock3 size={20}></LuClock3>
          <p className="a-font mb-0 py-2" style={{fontSize:'14px' , fontWeight:500 , color:'#202224'}}>{storageFacility?.working_hours}</p>
          </div>
          <hr></hr>
          <p className="a-font mb-0 py-2" style={{fontSize:'20px' , fontWeight:700 , color:'#202224'}}>Storage Access Hours</p>
          <div className="d-flex gap-3 align-items-center">
          <IoLogIn size={20}/>
          <p className="a-font mb-0 py-2" style={{fontSize:'14px' , fontWeight:500 , color:'#202224'}}>{storageFacility?.access_hours}</p>
          </div>
          <hr></hr>
          <p className="a-font mb-0 py-2" style={{fontSize:'20px' , fontWeight:700 , color:'#202224'}}>Address</p>
          <div className="d-flex gap-3 align-items-center">
          <FaLocationDot size={20}/>
          <p className="a-font mb-0 py-2" style={{fontSize:'14px' , fontWeight:500 , color:'#202224'}}>{storageFacility.address}</p>
          </div>

          {/* <div style={{ textAlign: "center" }}>
            <img
              src={
                storageFacility?.facility_images.length >= 2
                  ? storageFacility?.facility_images[1].image
                  : "/images/small.png"
              }
              className="img-fluid rounded"
              alt="lsdfj"
            />
            <ImageModal ref={imageModalRef} storageFacility={storageFacility} />
          </div> */}
        </li>
      </div>
      <div className=" d-flex flex-column gap-3 mt-4">
      <p className="a-font mb-0" style={{fontSize:'20px' , fontWeight:600 , color:'#000034'}}>How it works</p>
      <div className="d-flex gap-3 align-items-center text-wrap col-10">
        <img src="/images/step1.png" alt="steps" width={36} height={36}></img>
        <p className="a-font mb-0" style={{fontSize:'18px' , fontWeight:500 , color:'#202224'}}>1. Select your storage unit</p>
      </div>
      <div className="d-flex gap-3 align-items-center text-wrap col-10">
        <img src="/images/step2.png" alt="steps" width={36} height={36}></img>
        <p className="a-font mb-0" style={{fontSize:'18px' , fontWeight:500 , color:'#202224'}}>2. Reserve for free</p>
      </div>
      <div className="d-flex gap-3 align-items-center text-wrap col-10">
        <img src="/images/step3.png" alt="steps" width={36} height={36}></img>
        <p className="a-font mb-0" style={{fontSize:'18px' , fontWeight:500 , color:'#202224'}}>3. Visit your facility</p>
      </div>
      <div className="d-flex gap-3 align-items-center text-wrap col-10">
        <img src="/images/step4.png" alt="steps" width={36} height={36}></img>
        <p className="a-font mb-0" style={{fontSize:'18px' , fontWeight:500 , color:'#202224'}}>4. Move in</p>
      </div>
      </div>
    </div>
  );
};

export default Features;
