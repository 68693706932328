import React, { useEffect, useState } from "react";
import Pagination from "react-js-pagination";
import FilterCards from "./FilterCards";
import StorageUnitsList from "./StorageUnitsList";
import StorageUnitsMap from "./StorageUnitsMap";
import { useSelector } from "react-redux";
import Spinner from "../../../shared-components/Spinner/Spinner";
import { useDispatch } from "react-redux";
import { IoIosSkipBackward, IoIosSkipForward } from "react-icons/io";
import { getAllUsStorageFacilities } from "../../../actions/usStorageFacilityActions";
// import UnitStorageReviewsTable from "../../ServiceProviderDashboard/ServiceProviderDashboardComponents/UnitStorageReviewsTable";
import {
  getAllStorageUnits,
  getTotalStorageUnits,
  getAvailableStorageUnits,
  getOccupiedStorageUnits,
} from "../../../actions/storageUnitActions";
import { Card, Container, div, Col } from "react-bootstrap";

const FiltersAndStorages = ({ view }) => {
  const { loading, error, storageFacilties } = useSelector(
    (state) => state.usStorageFacilities,
  );
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(1);

  const handlePagination = (str) => {
    const nextPageUrl =
      str === "next" ? storageFacilties.next : storageFacilties.previous;

    if (nextPageUrl) {
      try {
        const url = new URL(nextPageUrl);
        const location = url.searchParams.get("location") || "";
        const storage_type = url.searchParams.get("storage_type") || "";
        const small_size = Number(url.searchParams.get("small_size")) || 0;
        const medium_size = Number(url.searchParams.get("medium_size")) || 0;
        const large_size = Number(url.searchParams.get("large_size")) || 0;
        const pageValue = Number(url.searchParams.get("page")) || 1;
        const formDataObject = {
          location,
          storage_type,
          small_size,
          medium_size,
          large_size,
          pageValue,
        };

        setCurrentPage(pageValue);
        dispatch(getAllUsStorageFacilities(formDataObject));
      } catch (error) {
        console.error("Error constructing URL:", error);
      }
    }
  };

  const facilityRatings = [
    {
      id: 1,
      avatar: "/images/usericondummy.png",
      name: "Laura K.",
      user: { email: "laura.k@miami.com" },
      facility_ratings: 4,
      comment:
        "The storage unit I rented through FindxStorage was clean, secure, and exactly as described. I appreciated the detailed descriptions and photos on the site—it made choosing the right place so easy!",
    },
    {
      id: 2,
      avatar: "/images/usericondummy.png",
      name: "Sarah",
      user: { email: "laura.k@miami.com" },
      facility_ratings: 4,
      comment:
        "I was impressed by how hassle-free the entire experience was with FindxStorage. The user-friendly website and honest reviews helped me make an informed decision. The storage unit was exactly what I needed—spacious and secure!",
    },
    {
      id: 3,
      avatar: "/images/usericondummy.png",
      name: "Olivia J.",
      user: { email: "laura.k@miami.com" },
      facility_ratings: 4,
      comment:
        "I’ve used several storage services in the past, but FindxStorage stands out. The transparency, ease of booking, and the quality of the units were unmatched!",
    },
  ];
  
  return (
    <div className="row my-3">
      <div className="col-md-3">
        <FilterCards />
      </div>
      <div className="col-md-9">
        {loading ? (
          <Spinner />
        ) : error ? (
          <div className="alert alert-danger" role="alert">
            {error.msg}
          </div>
        ) : (
          <>
            {view.list ? (
              <div>
                <StorageUnitsList storageFacilties={storageFacilties} />
                <div
                  className="d-flex justify-content-center align-items-center"
                  style={{
                    gap: "50px",
                  }}
                >
                  <Pagination
                    activePage={currentPage}
                    itemsCountPerPage={10}
                    totalItemsCount={storageFacilties?.count}
                    pageRangeDisplayed={5}
                    onChange={(page) => {
                      setCurrentPage(page);
                      handlePagination(
                        page === currentPage + 1 ? "next" : "previous",
                      );
                    }}
                    itemClass="page-item"
                    linkClass="page-link"
                  />
                </div>
              </div>
            ) : (
              <StorageUnitsMap storageFacilties={storageFacilties} />
            )}
          </>
        )}

        <hr size="30"></hr>
        {/* <div className="service-card p-4"
          style={{border:'0.3px solid #B9B9B9',
            borderRadius:'14px',
          }}>
          <div>

          </div>
          <hr></hr>
        </div> */}
        <Container className="d-flex flex-column gap-4 mt-5">
      {facilityRatings.length === 0 ? (
        <p className="text-center mt-5">This Facility currently has no reviews.</p>
      ) : (
        <div>
          {facilityRatings.map((review, index) => (
            <Col key={review.id || index} className="mb-4 w-full">
              <Card className="p-4" style={{
                border: '1px solid #DFDFDF',borderRadius:'10px',height:'auto',
              }}>
                <div className="d-flex justify-content-between">
                <div className="d-flex align-items-center">
                  <img
                    src={review.avatar || "/images/usericondummy.png"}
                    alt={review.name}
                    className="rounded-circle me-3"
                    style={{ width: "50px", height: "50px", objectFit: "cover" }}
                  />
                  <p className="a-font" style={{fontSize:'16px', color:'#000034' ,fontWeight:'500px',}}>{review.name}</p>
                  </div>
                    <div className="text-warning">
                      {"★".repeat(review.facility_ratings)}
                      {"☆".repeat(5 - review.facility_ratings)}
                    </div>
                </div>
                        <div style={{
                          border: "0.5px solid #DFDFDF",
                          margin: "20px 0",
                          width: "100%",   
                        }}></div>

                <p className="a-font" style={{ fontSize: "18px", fontWeight:'400px', color: "#000034",textAlign: "justify" }}>
                  {review.comment ? `"${review.comment}"` : 'No comment Provided'}
                </p>
              </Card>
            </Col>
          ))}
        </div>
      )}
    </Container>
      </div>
    </div>
  );
};

export default FiltersAndStorages;
